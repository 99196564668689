import React, {useCallback} from 'react';
import {Button, Popconfirm, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {setRedeem4GiftPhase} from '../../../Reducers/accounts';

function SetRedeem4GiftPhaseButton({className, selected, afterSuccess}) {
  const {isLoading} = useSelector(state => state.accounts.setRedeem4GiftPhase, shallowEqual);
  const dispatch = useDispatch();

  const submit = useCallback(() => {
    dispatch(setRedeem4GiftPhase({ids: selected}))
      .then(() => {
        afterSuccess();
        message.success('Phase set to Redeem4Gift!');
      })
      .catch(() => {
        message.error('Can\'t set to Redeem4Gift');
      });
  }, [dispatch, selected, afterSuccess]);

  return (<Popconfirm title={`Are you sure want to set Redeem4Gift phase for ${selected.length} accounts?`}
                      onConfirm={submit}
                      okText="Yes"
                      cancelText="No"
  >
    <Button loading={isLoading} className={className} type="dashed" size="large" disabled={!selected.length}>
      Redeem4Gift Phase
    </Button>
  </Popconfirm>);
}

export default SetRedeem4GiftPhaseButton;
