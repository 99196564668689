import React from 'react';
import b_ from 'b_';
import Table from 'antd/es/table/Table';
import Column from 'antd/es/table/Column';
import PrintDriverStatus from '../../../Components/Prints/PrintDriverStatus';
import ErrorBlock from '../../../Components/ErrorBlock';
import UpdateDriver from '../UpdateDriver';
import UpdateVpn from '../UpdateVpn';
import './styles.scss';
import Expandable from './Expandable';
import {Link} from "react-router-dom";

const b = b_.lock('DriverTable');

function DriverTable({ isLoading, error, payload, className, fetch }) {

  return (
    <>
      {error && <ErrorBlock message={error.message} description={error.description} />}
      <Table bordered
             className={className}
             loading={isLoading}
             pagination={false}
             scroll={{ x: 'max-content' }}
             dataSource={payload}
             rowKey="id"
             expandable={{
               expandedRowRender: record => <Expandable record={record} />,
             }}
      >
        <Column dataIndex="id" title="ID" />
        <Column dataIndex="group" title="Group" />
        <Column dataIndex="driver" title="Driver" />
        <Column dataIndex="host" title="Host" />
        <Column dataIndex="port" title="Port" />
        <Column dataIndex="type" title="Type" />
        <Column dataIndex="uses" title="Uses" />
        <Column dataIndex="status"
                title="Status"
                render={(status) => <PrintDriverStatus status={status} />}
        />
        <Column dataIndex="vpn" title="VPN" />
        <Column dataIndex="vpnProvider" title="Provider" />
        <Column dataIndex="vpnStatus"
                title="VPN Status"
                render={ (vpnStatus, record) => { if(record.updating === true) { return 'Updating...' } else { return vpnStatus } } }
        />
        <Column dataIndex="id"
                align="center"
                title="Actions"
                render={(id, record) => (
                  <div className={b('actions')}>
                    <UpdateDriver record={record} afterUpdate={fetch} id={id} />
                    <UpdateVpn afterUpdate={fetch} id={id} />
                  </div>
                )}
        />
      </Table>
    </>
  );
}

export default DriverTable;
