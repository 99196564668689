import React, {useCallback, useEffect, useState} from 'react';
import {message} from 'antd';
import b_ from 'b_';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import './styles.scss';
import TableSettings from "./TableSettings";
import useStorageState from "../../../Utils/useStorageState";
import ResizeTable from "../../../Components/ResizeableTitle";
import UpdateProxies from "./EditProxies";
import {changeProxies} from "../../../Reducers/proxies";
import UpdateCommentProxies from "./EditCommentProxies";
import UpdatePortProxies from "./EditPortProxies";
import {Link} from "react-router-dom";
import PrintProxiesState from "../../../Components/Prints/PrintProxiesStatus";
import ProxiesUpdateGroups from "../../../Components/EditGroupsProxies";


const b = b_.lock('AccountsTable');

function ProxiesTable({
                         proxies,
                         groups,
                         pagination,
                         onChange,
                         isLoading,
                         afterUpdate,
                         selected,
                         setSelected,
                         sorter,
                         onFilter
                       }) {

    const fieldsChange = [
        "id",
        "type",
        "host",
        "port",
        "user",
        "password",
        "status",
        "comment",
        "group"
    ]

  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
    proxy: state.proxies.editProxies.isLoading,
    driver: state.proxies.createProxies.isLoading,
  }), shallowEqual);

  const updateProxies = useCallback((params) => {
      dispatch(changeProxies([params])).then((e) => {
          try {
              message.success('Proxies completed update.');
              afterUpdate()
          }catch (e) {
              message.error('Can\'t update Proxies.');
              afterUpdate()
          }
      })
  }, [dispatch, afterUpdate]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('columnsProxies', {
      checkedList: [],
      indeterminate: false,
      checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
      setAllCheckbox({
          checkedList,
          indeterminate: !!checkedList.length && checkedList.length < columns.length,
          checkAll: checkedList.length === columns.length,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetColumnTable = () => ([
      {
          dataIndex:"id",
          title:"ID",
          align: "center",
          defaultView: true,
          width: 50,
      },
      {
          dataIndex:"type",
          title:"Type",
          align: "center",
          defaultView: true,
          width: 150,
          render:(type, rec) => (<UpdateProxies
              fieldsChange={fieldsChange}
              updateProxies={updateProxies}
              value={type}
              title={"type"}
              rec={rec}/>)
      },
      {
          dataIndex: "host",
          title: "Host",
          align: "center",
          width: 50,
          render: (host, rec) => (<UpdateProxies
              fieldsChange={fieldsChange}
              updateProxies={updateProxies}
              value={host}
              title={"host"}
              rec={rec}/>)
      },
      {
          dataIndex:"port",
          title:"Port",
          defaultView: true,
          align:"center",
          width: 70,
          render:(port, rec) => (<UpdatePortProxies
              fieldsChange={fieldsChange}
              updateProxies={updateProxies}
              value={port}
              title={"port"}
              rec={rec}/>)
      },
      {
          dataIndex:"user",
          title:"User",
          align: "center",
          defaultView: true,
          width: 70,
          render:(user, rec) => (<UpdateProxies
              fieldsChange={fieldsChange}
              updateProxies={updateProxies}
              value={user}
              title={"user"}
              rec={rec}/>)
      },
      {
          dataIndex:"password",
          title:"Password",
          align: "center",
          defaultView: true,
          width: 70,
          render:(status, rec) => (<UpdateProxies
              fieldsChange={fieldsChange}
              updateProxies={updateProxies}
              value={status}
              title={"password"}
              rec={rec}/>)
      },
      {
          title:"Group",
          dataIndex:"group",
          align: "center",
          defaultView: true,
          render:(group, rec) => (<ProxiesUpdateGroups
              fieldsChange={fieldsChange}
              handleSave={updateProxies}
              proxyGroups={group}
              title={"group"}
              rec={rec}/>)
      },
      {
          dataIndex:"status",
          title:"Status",
          align: "center",
          defaultView: true,
          width: 70,
          render:(status, rec) => (<PrintProxiesState
              fieldsChange={fieldsChange}
              handleSave={updateProxies}
              status={status}
              field={"status"}
              rec={rec}/>)
      },
      {
          dataIndex:"workers",
          title:"Workers",
          align: "center",
          defaultView: true,
          width: 30,
          render:(workers, rec) =>
              <div>
                  {workers.length ? workers.map(e => <div>{e}</div>) : ""}
              </div>
      },
      {
          dataIndex:"comment",
          title:"Comment",
          defaultView: true,
          align:"center",
          width: 70,
          render:(comment, rec) => (<UpdateCommentProxies
              fieldsChange={fieldsChange}
              updateProxies={updateProxies}
              value={comment}
              title={"comment"}
              rec={rec}/>),
      },
  ]);

  const [columns, setColumns] = useState(GetColumnTable());

  useEffect(() => {
      if(AllCheckbox.checkAll) {
          setAllCheckbox({
              checkedList:GetColumnTable().map(e => e.title),
              indeterminate: false,
              checkAll: true,
          })
      }
  },[AllCheckbox.checkAll])

  const onChangeCheckAll = useCallback((e) => {
      setAllCheckbox({
          checkedList: (e.target.checked && columns.map(e => e.title)) || [],
          indeterminate: false,
          checkAll: e.target.checked,
      });
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      setColumns(GetColumnTable())
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter, groups])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  return <ResizeTable bordered
                      className={b()}
                      scroll={{x: 'max-content'}}
                      dataSource={proxies}
                      onChange={onChange}
                      columns={columnsFilter}
                      pagination={pagination}
                      loading={loader}
                      onFilter={onFilter}
                      size="small"
                      rowClassName={() => b('lock')}
                      rowKey="id"
                      style={{ wordBreak: "break-word", wordWrap: "break-word" }}
                      title={() => <TableSettings
                          onChangeCheckAll={onChangeCheckAll}
                          allChecks={AllCheckbox}
                          Checkboxes={AllCheckbox.checkedList}
                          TableColumn={() =>GetColumnTable(sorter,true)}
                          setCheckbox={onChangeCheckList}
                      />}
                      rowSelection={{
                          selectedRowKeys: selected,
                          onChange: setSelected,
                      }}
  >
  </ResizeTable>;
}

export default ProxiesTable;
