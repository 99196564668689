import React, {useCallback, useState} from 'react';
import b_ from 'b_';
import {Button, Drawer, Form, Input, message, Select} from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import {LoadingOutlined} from "@ant-design/icons";
import {createProxies} from "../../../Reducers/proxies";

const b = b_.lock('CreateProxy');

function CreateProxy({ className, afterSuccess, groups }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);



  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const [proxy, setProxy] = useState("");
  const handleSuccess = useCallback((data) => {
    Object.keys(data).map(e => !data[e] ? data[e] = "" : null)
    dispatch(createProxies([data])).then(() => {
      close();
      afterSuccess();
      message.success('Proxy has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.proxies.createProxies.error, shallowEqual);

  return (
    <>
      <Button
          type="primary"
          size="medium"
          className={className}
          icon={<PlusOutlined />}
          style={{ backgroundColor: "#1976d2"}}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Proxy" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            rules={[
                { required: true, message: 'Please input type.' },
            ]}
            label="Type:"
            name="type"
          >
              <Select onChange={(e) => setProxy(e)}>
                  {["http", "socks5"].map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input host.' },
            ]}
            label="Host:"
            name="host"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="User:"
            name="user"
          >
            <Input type="text"/>
          </Form.Item>
          <Form.Item
            label="Password:"
            name="password"
          >
              <Input type="text"/>
          </Form.Item>
          <Form.Item
              rules={[
                  { required: true, message: 'Please input group.' },
              ]}
              label="Group:"
              name="group"
          >
              <Select>
                  {groups && groups.map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          <Form.Item
              rules={[
                  { required: true, message: 'Please input status.' },
              ]}
            label="Status:"
            name="status"
          >
              <Select>
                  {["Enabled", "Disabled"].map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          <Form.Item
            rules={[
                { required: true, message: 'Please input port.' },
            ]}
            label="Port:"
            name="port"
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Comment:"
            name="comment"
          >
            <TextArea />
          </Form.Item>
          <Form.Item>
              <Button type="primary" icon={isLoading && <LoadingOutlined/>} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateProxy;
