import { applyMiddleware, createStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import fec from 'front-end-common';
import rootReducer from '../Reducers';

const utilMiddleware = fec.utils.reducerTools.middleware;
export const history = createBrowserHistory();

export default function (initialState = {}) {
  let middleware = applyMiddleware(utilMiddleware, routerMiddleware(history));

  return createStore(rootReducer, initialState, middleware);
};
