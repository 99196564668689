import React from 'react';
import { Button, Modal } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import fec from 'front-end-common';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { EyeOutlined } from '@ant-design/icons';

const useBoolean = fec.hooks.useBoolean;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function ImageViews({ className, images }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  return <>
    <Button className={className} type="primary" onClick={open} icon={<EyeOutlined />}>
      Show
    </Button>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} height={"90vh"} centered>
      <Carousel className="p-16">
        {images.map((img, key) => (
          <div key={key}>
            <img src={BACKEND_URL + img} alt="screenshot" style={{ maxHeight: "70vh", pointerEvents: "unset", objectFit: "contain" }} />
          </div>
        ))}
      </Carousel>
    </Modal>
  </>;
}

export default ImageViews;
