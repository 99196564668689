import React, {useCallback} from 'react';
import b_ from 'b_';
import {Button, Drawer, Form, Input, message} from 'antd';
import fec from 'front-end-common';
import GroupSelector from '../../Accounts/Selectors/GroupSelector';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import {createPurchaseJob} from '../../../Reducers/purchaseJobs';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';

const useBoolean = fec.hooks.useBoolean;
const b = b_.lock('CreatePurchaseJob');

function CreatePurchaseJob({className, afterSuccess}) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.purchaseJobs.create.isLoading, shallowEqual);

  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const handleSuccess = useCallback((data) => {
    dispatch(createPurchaseJob(data)).then(() => {
      close();
      afterSuccess();
      message.success('Job has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.purchaseJobs.create.error, shallowEqual);

  return (
    <>
      <Button type="primary"
              size="large"
              className={className}
              icon={<PlusOutlined/>}
              onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Job" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message}/>}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            rules={[
              {required: true, message: 'Please select group.'},
            ]}
            label="Group:"
            name="group"
          >
            <GroupSelector/>
          </Form.Item>
          <Form.Item
            rules={[
              {required: true, message: 'Please input friend id.'},
            ]}
            label="Friend Id:"
            name="friendId"
          >
            <Input type="text"/>
          </Form.Item>

          <Form.Item
            rules={[
              {required: true, message: 'Please input sub id.'},
            ]}
            label="Sub Id:"
            name="subId"
          >
            <Input type="text"/>
          </Form.Item>
          <Form.Item
            rules={[
              {required: true, message: 'Please input price.'},
            ]}
            label="Price:"
            name="price">
            <Input type="number"/>
          </Form.Item>
          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreatePurchaseJob;
