import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import { fetchConfigs } from '../../Reducers/config';
import {getProxies} from "../../Reducers/proxies";
import CreateProxy from "./CreateProxy";
import ProxiesTable from "./ProxiesTable";
import {fetchAuxAccount} from "../../Reducers/accounts";
import { useOutletContext } from 'react-router';
import { Result } from 'antd';
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('Accounts');

function Proxies() {
  const {
    pagination,
    onChange,
    isLoading,
    fetch,
    sort,
    error,
    onFilter
  } = useTableState(state => state.proxies.proxies, getProxies);

  const dispatch = useDispatch();

  const list = useSelector(state => state.proxies.proxies.payload, shallowEqual);

  const {payload: auxPayload, isLoading: auxLoading} = useSelector(state => state.accounts.aux, shallowEqual);

  useEffect(() => {
      !auxPayload && dispatch(fetchAuxAccount());
  },[dispatch, auxPayload])

  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const [selectedRow, setSelectedRow] = useState([]);

  const roles = useOutletContext()

  return (
    privilegeGuard(roles, "PROXIES_VIEW") ? <div className={b()}>
    <Title className="mb">
        Proxies
    </Title>
    <div className={b('buttonsBlock')}>
          <CreateProxy afterSuccess={fetch} groups={auxPayload?.groups}/>
    </div>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <ProxiesTable selected={selectedRow}
                  groups={auxPayload?.groups}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   pagination={pagination}
                   onChange={onChange}
                   isLoading={isLoading}
                   sorter={sort}
                   proxies={list}
                   onFilter={onFilter}
    />
  </div> :
        <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
  )
}

export default Proxies;
