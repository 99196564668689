import React, {useCallback} from 'react';
import {Button, Drawer, Form, message, Select} from 'antd';
import fec from 'front-end-common';
import ErrorBlock from '../../../Components/ErrorBlock';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {deArchiveAccount} from '../../../Reducers/accounts';

const useBoolean = fec.hooks.useBoolean;

function DeArchive({ className, accountId, afterUpdate }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { error, isLoading } = useSelector(state => state.accounts.deArchiveAccount, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(({ phase }) => {
    dispatch(deArchiveAccount({ id: accountId, phase: phase })).then(() => {
      message.success('Account de-archived!');
      afterUpdate();
      close();
    });
  }, [dispatch, accountId, close]);

  return <>
    <Button className={className} type="link" size="small" onClick={open}>De Archive</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="De Archive"
            placement="right"
            width={600}
    >
      {error && <ErrorBlock message={error.message} />}
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            { required: true, message: 'Please input new Steam Id.' },
          ]}
          label="Phase"
          name="phase"
        >
          <Select size="small" dropdownStyle={{ minWidth: "15ch" }}>
            <Select.Option value="Redeem">Redeem</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            OK
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
}

export default DeArchive;
