
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { parseQueryString, prepareQueryString } from '../Services/ApiService';
const EMPTY_FILTERS = {};

const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100', '200', '500'];

function buildUseTable(useDispatch, useSelector, shallowEqual, useLocation) {
  return function (selector, fetchAction) {
    const location = useLocation();
    // const history = useHistory();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {
      payload,
      isLoading,
      error,
    } = useSelector(selector, shallowEqual);

    const { list, total } = useMemo(() => ({
      list: (payload && payload.list) || [],
      total: (payload && payload.total) || 0,
    }), [payload]);

    const params = useMemo(
      () => ({ limit: 50, offset: 0, ...parseQueryString(location.search) }),
      [location.search],
    );

    const fetch = useCallback(() => {
      dispatch(fetchAction(params));
    }, [params, dispatch, fetchAction]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    const onChange = useCallback(({ pageSize, current }, filters, { field, order }) => {
      const limit = pageSize;
      let offset = (current - 1) * pageSize;

      if (params.offset === offset) {
        offset = 0;
      }

      const orderBy = (field && order) ? field : null;
      const orderPath = (orderBy && order) || null;

      const newFilters = Object.keys(filters)
        .filter(key => filters[key] !== null)
        .reduce((acc, cur) => ({ ...acc, [cur]: filters[cur] }), {});

      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          limit,
          filters: newFilters,
          offset,
          orderBy,
          orderPath,
        }),
      });
    }, [location.pathname, params, navigate]);

    const onFilter = useCallback((filters) => {
      const newFilters = Object.keys(filters)
        .filter(key => filters[key] !== null)
        .reduce((acc, cur) => ({ ...acc, [cur]: filters[cur] }), {});

      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          offset: 0,
          filters: newFilters,
        }),
      });
    }, [location.pathname, params, navigate]);

    const onSearch = useCallback((query = '') => {
      navigate({
        pathname: location.pathname,
        search: prepareQueryString({
          ...params,
          offset: 0,
          query: query.trim() ? query : undefined,
        }),
      });
    }, [navigate, location.pathname, params]);

    const sort = useMemo(() => ({
      field: params.orderBy,
      order: params.orderPath,
    }), [params.orderBy, params.orderPath]);

    const filters = useMemo(() => params.filters || EMPTY_FILTERS, [params.filters]);
    const query = useMemo(() => params.query || '', [params.query]);

    const pagination = useMemo(() => ({
      total,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      showSizeChanger: true,
      current: Math.round((params.offset || 0) / (params.limit || 50) + 1),
      pageSize: parseInt(params.limit, 10) || 50,
    }), [params.offset, params.limit, total]);

    return {
      error,
      list,
      isLoading,
      pagination,
      onChange,
      sort,
      filters,
      onSearch,
      query,
      fetch,
      onFilter,
    };
  }
}

export default buildUseTable(useDispatch, useSelector, shallowEqual, useLocation);
