import React from 'react';
import App from './App';
import SignIn from '../Pages/SingIn';
// import RedirectUserToMainPage from '../HOC/RedirectUserToMainPage';
import Layout from '../Components/Layouts';
// import RedirectGuestToSignInPage from '../HOC/RedirectGuestToSignInPage';
import Accounts from '../Pages/Accounts';
import ArchiveAccounts from '../Pages/Accounts/ArchiveAccounts';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import RedirectPage from '../Pages/RedirectPage';
import RedeemJob from '../Pages/RedeemJob';
import PurchaseJob from '../Pages/PurchaseJob';
import UnorderedListOutlined from '@ant-design/icons/lib/icons/UnorderedListOutlined';
import OtherJob from '../Pages/OtherJob';
import AccountPage from '../Pages/AccountPage';
import ProfileOutlined from '@ant-design/icons/lib/icons/ProfileOutlined';
import Config from '../Pages/Config';
import Driver from '../Pages/Driver';
import CloudSyncOutlined from '@ant-design/icons/lib/icons/CloudSyncOutlined';
import FontColorsOutlined from '@ant-design/icons/lib/icons/FontColorsOutlined';
import Proxies from "../Pages/Proxies";
import { GlobalOutlined } from "@ant-design/icons";
import { useRoutes } from 'react-router';
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage';
import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage';



function Routes() {
  const element = useRoutes([
    {
      path: '/sign-in',
      exact: true,
      element: <RedirectUserToMainPage />,
    },
    {
      path: '/redirect',
      exact: true,
      element: <RedirectPage />,
    },
    {
      path: '/',
      element: <RedirectGuestToSignInPage />,
      children: [
        {
          path: '/',
          exact: true,
          element: <Accounts />,
        },
        {
          path: '/archive',
          exact: true,
          element: <ArchiveAccounts />,
        },
        {
          path: '/purchase-job',
          exact: true,
          element: <PurchaseJob />,
        }, {
          path: '/redeem-job',
          exact: true,
          element: <RedeemJob />,
        }, {
          path: '/other-job',
          exact: true,
          element: <OtherJob />,
        }, {
          path: '/account/:id',
          exact: true,
          element: <AccountPage />,
        }, {
          path: '/config',
          exact: true,
          element: <Config />,
        }, {
          path: '/driver',
          exact: true,
          element: <Driver />,
        }, {
          path: '/proxies',
          exact: true,
          element: <Proxies />,
        }
      ]
    },
  ])
  return element;
}

// const Routes = [
//   {
//     component: App,
//     routes: [
//       {
//         path: '/sign-in',
//         exact: true,
//         component: RedirectUserToMainPage(SignIn),
//       }, {
//         path: '/redirect',
//         exact: true,
//         component: RedirectPage,
//       },
//       {
//         path: '/',
//         component: RedirectGuestToSignInPage(Layout),
//         routes: [
//           {
//             path: '/',
//             exact: true,
//             component: Accounts,
//           }, {
//             path: '/archive',
//             exact: true,
//             component: ArchiveAccounts,
//           }, {
//             path: '/purchase-job',
//             exact: true,
//             component: PurchaseJob,
//           }, {
//             path: '/redeem-job',
//             exact: true,
//             component: RedeemJob,
//           }, {
//             path: '/other-job',
//             exact: true,
//             component: OtherJob,
//           }, {
//             path: '/account/:id',
//             exact: true,
//             component: AccountPage,
//           }, {
//             path: '/config',
//             exact: true,
//             component: Config,
//           }, {
//             path: '/driver',
//             exact: true,
//             component: Driver,
//           }, {
//             path: '/proxies',
//             exact: true,
//             component: Proxies,
//           }
//         ],
//       },
//     ],
//   },
// ];

export const links = [
  {
    href: '/',
    icon: <UserOutlined />,
    title: 'Account',
  }, {
    href: '/archive',
    icon: <FontColorsOutlined />,
    title: 'Archive',
  }, {
    href: '/purchase-job',
    icon: <UnorderedListOutlined />,
    title: 'Purchase Jobs',
  }, {
    href: '/redeem-job',
    icon: <UnorderedListOutlined />,
    title: 'Redeem Jobs',
  }, {
    href: '/other-job',
    icon: <UnorderedListOutlined />,
    title: 'Other Jobs',
  }, {
    href: '/config',
    icon: <ProfileOutlined />,
    title: 'Config',
  }, {
    href: '/driver',
    icon: <CloudSyncOutlined />,
    title: 'Driver',
  }, {
    href: '/proxies',
    icon: <GlobalOutlined />,
    title: 'Proxies',
  },
];

export default Routes;
