import moment from 'moment';

const FORMATS = {
  DATE: 'DD/MM/YYYY',
  DATE_WITH_TIME: 'DD/MM/YYYY HH:mm:ss',
  TIME_WITHOUT_DATE: 'HH:mm:ss',
};

export function serverDateToDateWithTime(date) {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMATS.DATE_WITH_TIME);
}

export function serverDateToDate(date) {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMATS.DATE);
}

export function serverDateToTimeWithoutDate(date) {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMATS.TIME_WITHOUT_DATE);
}

export function unixToMoment(unix) {
  return (+unix && moment(+unix)) || null;
}

export function momentToUnix(v) {
  return (v && v.valueOf()) || null;
}

export function unixFromNow(unix, startUnix = null) {
  if (!unix) return null;

  return timeSince(moment(startUnix && +startUnix).unix() - moment(+unix).unix());
}

export function timeSince(sec) {
  const sec_num = parseInt(sec, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor(sec_num / 60) % 60;
  const seconds = sec_num % 60;
  // const arr = ['h', 'm', 's'];

  return `${hours !== 0 ? (hours < 10 ? '0' + hours + 'h ' : hours + 'h ') : ''}${minutes !== 0 ? (minutes < 10 ? '0' + minutes + 'm ' : minutes + 'm ') : ''}${seconds !== 0 ? (seconds < 10 ? '0' + seconds + 's ' : seconds + 's ') : ''}`
  // return [hours, minutes, seconds]
  //   .map(v => v < 10 ? '0' + v : v)
  //   .reduce((acc, cur, i) => `${acc} ${cur}${arr[i]} `, '');
}

export function calculateTimeDifference(txtime) {
  var currentTime = new Date().getTime();
  var timeDifference = currentTime - txtime;

  var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return days + " days";
  } else if (hours > 0) {
    return hours + " hours";
  } else {
    return minutes + " minutes";
  }
}

export function findClosestTimestamp(data) {
  var currentTime = new Date().getTime(); // Get current time in milliseconds

  if (data.length === 0) {
    return 'n/d'
  }

  var closestTimestamp = data.reduce((closest, current) => {
    var closestDiff = Math.abs(closest?.ts - currentTime);
    var currentDiff = Math.abs(current?.ts - currentTime);

    return currentDiff < closestDiff ? current : closest;
  });

  // console.log('closestTimestamp', closestTimestamp)

  return closestTimestamp.ts;
}

