import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import ErrorBlock from '../ErrorBlock';

const DRIVER_STATUS = process.env.REACT_APP_DRIVERS_STATUS;
let parsedDriverStatus = null;

try {
  parsedDriverStatus = JSON.parse(DRIVER_STATUS);
} catch (e) {
  console.log('parsedDriverStatus e:', e);
}

function DriverStatusSelector({ className, value, onChange, defaultValue, placeholder, disabled }) {
  if (!parsedDriverStatus) {
    return <ErrorBlock message="ENV REACT_APP_DRIVERS_STATUS is not set!" />
  }

  return (
    <Select defaultValue={defaultValue}
            placeholder={placeholder}
            className={className}
            allowClear
            value={value}
            onChange={onChange}
            style={{ width: '100%' }}
            disabled={disabled}
    >
      {parsedDriverStatus.map(status => <Select.Option value={status}>{status}</Select.Option>)}
    </Select>
  );
}

DriverStatusSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
};

export default DriverStatusSelector;
