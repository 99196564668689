import React from 'react';
import { Row, Col } from 'antd';
import JSONTree from 'react-json-tree';
import theme from '../../../Utils/jsonTheme';
import PrintDate from '../../Prints/PrintDate';
import PrintDateOnlyTime from '../../Prints/PrintDateOnlyTime';

function Expandable({ className, record }) {
  const { trace, changes, resultDetails } = record;

  return <Row className={className} gutter={8}>
    <Col>
      <b className="block mb">Trace</b>
      <ul>
        {trace.map(({ name, time }) => (
          <li>{name} - <PrintDateOnlyTime date={time} /></li>
        ))}
      </ul>
    </Col>
    <Col style={{maxWidth: '40vw'}}>
      <b className="block mb">Changes</b>
      <div style={{maxHeight: '50vh', overflowY: 'scroll'}}>
      <JSONTree  hideRoot theme={theme} data={changes} shouldExpandNode={() => true} />
      </div>
    </Col>
    <Col style={{maxWidth: '40vw'}}>
      <b className="block mb">Result Details</b>
      <div style={{maxHeight: '50vh', overflowY: 'scroll'}}>
      <JSONTree hideRoot theme={theme} data={resultDetails} shouldExpandNode={() => true} />
      </div>
    </Col>
  </Row>
}

export default Expandable;
