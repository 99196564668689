import React, {useCallback} from 'react';
import {Button, Drawer, Form, message} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import {updateVpnDriver} from '../../../Reducers/driver';
import VpnLookup from '../../../Components/VpnLookup';
import frc from 'front-end-common';
import DriversTypeSelector from "../../../Components/DriversTypeSelector";
import VpnProviderSelector from "../../../Components/VpnProviderSelector";

const useBoolean = frc.hooks.useBoolean;

function UpdateVpn({className, id, afterUpdate}) {
  const [form] = Form.useForm();
  const {setTrue: open, setFalse: close, value: isOpen} = useBoolean(false);
  const {isLoading, error} = useSelector(state => state.driver.updateVpn, shallowEqual)
  const dispatch = useDispatch();

  const handleSuccess = useCallback(({provider, vpn}) => {
    dispatch(updateVpnDriver({id, provider, vpn})).then(() => {
      message.success('VPN has been updated!');
      afterUpdate();
      close();
    })
  }, [id, dispatch, afterUpdate, close]);

  return (
    <>
      <Button size="small" className={className} type="link" onClick={open}>update vpn</Button>
      <Drawer onClose={close}
              visible={isOpen}
              title="Update VPN"
              placement="right"
              width={600}
      >
        {error && <ErrorBlock message={error.message} description={error.description}/>}
        <Form layout="vertical" onFinish={handleSuccess} form={form}>
          <Form.Item
            rules={[
              {required: true, message: 'Please select provider.'},
            ]}
            label="Provider"
            name="provider"
          >
            <VpnProviderSelector/>
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => {
              return (
                <Form.Item
                  rules={[
                    {required: true, message: 'Please select vpn.'},
                  ]}
                  label="New Vpn"
                  name="vpn"
                >
                  <VpnLookup provider={form.getFieldValue('provider')}/>
                </Form.Item>);
            }}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Update Vpn
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateVpn;
