import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import "./styles.scss"

const STATE_TO_COLOR = {
  Enabled: 'green',
  Disabled: 'gray',
  Invalid: 'red',
  TempInvalid: 'yellow',
};

function PrintAccountState({ className, status }) {
  return <Tag className={[className, status]} color={STATE_TO_COLOR[status]}>{status}</Tag>;
}

PrintAccountState.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATE_TO_COLOR)).isRequired,
};

PrintAccountState.defaultProps = {
  className: '',
};

export default PrintAccountState;
