import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, Result, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import JobsTable from '../../Components/JobsTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import JobFilters from '../../Components/JobFilters';
import { cancelOtherJobs, fetchOtherJobs } from '../../Reducers/otherJobs';
import './styles.scss';
import { useOutletContext } from 'react-router';
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('OtherJob');

function OtherJob() {
  const { fetch, onFilter, error, ...props } = useTableState(state => state.otherJobs.list, fetchOtherJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.otherJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelOtherJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  const roles = useOutletContext()

  return (
    privilegeGuard(roles, "other_jobs_view") || privilegeGuard(roles, "admin") ?
    
    <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Other Jobs
      {privilegeGuard(roles, "admin") && 
      <Button onClick={cancelJobs}
              disabled={selectedRow.length === 0}
              icon={<StopOutlined />}
              type="primary"
              size="large"
              loading={props.isLoading || cancelLoading}
      >
        Cancel Jobs
      </Button>
}
    </Title>
    <JobFilters filters={props.filters} onFilter={onFilter} className="mb" withCodeFilter={false}
                withGiftCodeFilter={false}/>
    <JobsTable {...props} selected={selectedRow} setSelected={setSelectedRow} />
  </div> :
  <Result
  status="403"
  title="403"
  subTitle="Sorry, you are not authorized to access this page."
/>
  )
}

export default OtherJob;
