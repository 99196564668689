import React from 'react';
import { Switch, Tag } from 'antd';
import EditableField from '../EditableField';
import SelectEnabledJobs from '../SelectEnabledJobs';
import './styles.scss';
import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import b_ from "b_";

const ENABLED_JOBS = JSON.parse(process.env.REACT_APP_ENABLED_JOBS);

const b = b_.lock('EnabledJobs');

function EnabledJobs({ enabledJobs, isLoading, handleSave, iconClassName, field, rec, forbidden }) {


  const handleUpdateTag = (job) => {
    const params = {
        accountIds: [
            rec.id
        ],
    }

    params[field] = enabledJobs.includes(job) ? [...enabledJobs.filter(code => code !== job)]
        : [...enabledJobs.filter(em => em !== "Enabled"), job]

  console.log('params', params)

   return handleSave(params);
}


  return (
    <div className="EnabledJobs">
      {ENABLED_JOBS.map((job) => (
        <Tag className={[b('tag'), enabledJobs.includes(job) ? 'EnabledJobs__tag_green' : 'EnabledJobs__tag_red']}
             color={enabledJobs.includes(job) ? 'green' : 'red'}
        >
          {job}
          {!forbidden ? <Switch
                  size="small"
                  className={enabledJobs.includes(job) ? 'switch__green' : 'switch__red'}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked = {enabledJobs.includes(job)}
                  onClick={(e) => handleUpdateTag(job)}
                  /> : enabledJobs.includes(job) ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
        </Tag>
      ))}
    </div>
  )
  // </EditableField>;

}

export default EnabledJobs;
