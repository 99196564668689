import React, { useCallback } from 'react';
import {Button, Checkbox, Input, message, Select, Switch, Table, Tag} from 'antd';
import b_ from 'b_';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import EditableField from '../../../Components/EditableField';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  changeStatusAccount,
  updatePhaseAccount,
  updateBalLimitAccount,
  updateCommentAccount,
  updateGroupAccount,
  updateDriverAccount,
  updateEnabledJobsAccount,
  updateLoginAccount,
  updateProxyAccount,
    setRedeem4GiftPhase,
    updateCardAccount,
    updateAccounts,
    updateRedeemCorrect
} from '../../../Reducers/accounts';
import TextArea from 'antd/lib/input/TextArea';
import ChangePassword from '../ChangePassword';
import './styles.scss';
import { Link } from 'react-router-dom';
import ChangeToken from '../ChangeToken';
import ChangeSteamId from "../ChangeSteamId";
import Expanded from './Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import GroupSelector from '../Selectors/GroupSelector';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import RedeemCorrect from '../../../Components/RedeemCorrect';
import { calculateTimeDifference, findClosestTimestamp, timeSince, unixFromNow } from '../../../Utils/dates';

const b = b_.lock('AccountsTable');

function AccountsTable({ list, pagination, onChange, isLoading, afterUpdate, selected, setSelected, onFilter, filters, sorter, privilegeGuard, roles }) {
  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
      proxy: state.accounts.updateProxy.isLoading,
      driver: state.accounts.updateDriver.isLoading,
      card: state.accounts.updateCard.isLoading,
      comment: state.accounts.updateComment.isLoading,
      status: state.accounts.changeStatus.isLoading,
      login: state.accounts.updateLogin.isLoading,
      balLimit: state.accounts.updateBalLimit.isLoading,
      purchLimit: state.accounts.updatePurchLimit.isLoading,
      enabledJobs: state.accounts.updateEnabledJobs.isLoading,
      updateGroup: state.accounts.updateGroup.isLoading,
      updateAccounts: state.accounts.updateAccounts.isLoading
  }), shallowEqual);

  const { accountCodeFilters, groups } = useSelector(
    (state) => {
      if (!state.config.data.payload) return { accountResultCodeFilter: null, groups: null };

      try {
      let accountResultCodeFilter = state.config.data.payload.find(({ name }) => name === 'accountResultCodeFilter');
      accountResultCodeFilter = accountResultCodeFilter ? JSON.parse(accountResultCodeFilter.value) : null;

      let groups = state.config.data.payload.find(({ name }) => name === 'groups');
      groups = groups ? JSON.parse(groups.value) : null;

      return { accountResultCodeFilter, groups };
      } catch (e) {
        return { accountResultCodeFilter: null, groups: null };
      }
    },
    shallowEqual
  );
  
  const updateGroup = useCallback((id, group) => {
    dispatch(updateGroupAccount({ id, group })).then(() => {
      message.success('group has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update Group.');
    });
  }, [dispatch, afterUpdate]);

  const updateEnabledJobs = useCallback((enabledJobs) => {
    dispatch(updateEnabledJobsAccount(enabledJobs)).then(() => {
      message.success('Enabled Jobs has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update Enabled Jobs.');
    });
  }, [dispatch, afterUpdate]);


  const updateRedeemCorrectAccount = useCallback((data) => {
    
    dispatch(updateRedeemCorrect(data)).then(() => {
      message.success('Redeem phase has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update Redeem phase');
    });
  }, [dispatch, afterUpdate]);

  const updateGameFlow = useCallback((data) => {
    
    dispatch(updateRedeemCorrect(data)).then(() => {
      message.success('Game flow has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update Game flow');
    });
  }, [dispatch, afterUpdate]);

  const updateBalLimit = useCallback((id, balLimit) => {
    dispatch(updateBalLimitAccount({ id, balLimit: +balLimit })).then(() => {
      message.success('Bal Limit has been updated!');
      afterUpdate();
    }).catch((error) => {
      console.log(error);
      message.error('Can\'t update bal limit');
    });
  }, [dispatch, afterUpdate]);

  const updateLogin = useCallback((id, login) => {
    dispatch(updateLoginAccount({ id, login })).then(() => {
      message.success('Login has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update login');
    });
  }, [dispatch, afterUpdate]);

  const updateProxy = useCallback((id, proxy) => {
    dispatch(updateProxyAccount({id, proxy})).then(() => {
      message.success('Proxy has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update proxy');
    });
  }, [dispatch, afterUpdate]);

  const updateDriver = useCallback((id, driver) => {
    dispatch(updateDriverAccount({ id, driver })).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update driver');
    });
  }, [dispatch, afterUpdate]);

  const updateCard = useCallback((id, card) => {
    dispatch(updateCardAccount({ id, card })).then(() => {
        message.success('Card has been updated!');
        afterUpdate();
    }).catch(error => {
        console.log(error);
        message.error('Can\'t update card');
    });
  }, [dispatch, afterUpdate]);

  const updateAccount = useCallback((id, data) => {
    dispatch(updateAccounts({ accountIds: [id], ...data })).then(() => {
        message.success('Account has been updated!');
        afterUpdate();
    }).catch(error => {
        console.log(error);
        message.error('Can\'t update account');
    });
  }, [dispatch, afterUpdate]);

  const updateComment = useCallback((id, comment) => {
    dispatch(updateCommentAccount({ id, comment })).then(() => {
      message.success('Comment has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update comment');
    });
  }, [dispatch, afterUpdate]);

  const updateStatus = useCallback((id, status) => {
    dispatch(changeStatusAccount({ id, action: status.toLowerCase() })).then(() => {
      message.success('Status has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update status');
    });
  }, [dispatch, afterUpdate]);

  const updatePhase = useCallback((id, phase) => {
    dispatch(updatePhaseAccount({ id, phase })).then(() => {
      message.success('Phase has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update phase');
    });
  }, [dispatch, afterUpdate]);

  const setRedeem4GiftPhaseOnClick = useCallback((id) => {
    dispatch(setRedeem4GiftPhase({ ids: [id] })).then(() => {
      message.success('Phase set to Redeem4Gift!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t set to Redeem4Gift');
    });
  }, [dispatch, afterUpdate]);


  return <Table bordered
                className={b()}
                scroll={{ x: 'max-content' }}
                dataSource={list}
                onChange={onChange}
                pagination={pagination || false}
                loading={isLoading}
                onFilter={onFilter}
                rowClassName={() => b('lock')}
                rowKey="id"
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                expandable={{
                  expandedRowRender: record => <Expanded record={record} />,
                }}

  >
    <Table.Column dataIndex="id"
                  title="ID"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'id' ? sorter.order : null}
                  render={id => pagination != null ? <Link to={`account/${id}`}>{id}</Link> : id}
    />
    <Table.Column dataIndex="login"
                  title="Login"
    />
    <Table.Column dataIndex="phase"
                  title="Phase"
                  width={150}
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'phase' ? sorter.order : null}
                  {...(filters != null ? {
                      filteredValue: filters['phase'] || null,
                      filters: [{text: 'Redeem', value: 'Redeem'},],
                      filterMultiple: true
                  } : {})}
                  render={(phase, rec) => (
                    <EditableField 
                    forbidden={!privilegeGuard(roles, "admin")}
                    handleSave={(newPhase) => updatePhase(rec.id, newPhase)}
                                   title="Update Phase"
                                   withTitle
                                   titlePopover="Edit Phase"
                                   isLoading={loadings.phase || isLoading}
                                   iconClassName={b('edit')}
                                   initialValue={phase}
                                   changeBlock={(props) => (
                                     <Select {...props} size="small" dropdownStyle={{ minWidth: "15ch" }}>
                                       <Select.Option value="Redeem">Redeem</Select.Option>
                                       <Select.Option value="Archive">Archive</Select.Option>
                                     </Select>
                                   )}>
                        <Tag>{phase}</Tag>
                    </EditableField>
                  )}
    />
    <Table.Column dataIndex="status"
                  title="Status"
                  width={150}
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'status' ? sorter.order : null}
                  {...(filters != null ? {
                      filters: [
                          {text: 'Enabled', value: 'Enabled'},
                          {text: 'Disabled', value: 'Disabled'},
                          {text: 'TempInvalid', value: 'TempInvalid'},
                          {text: 'Invalid', value: 'Invalid'},
                      ],
                      filterMultiple: true,
                      filteredValue: filters['status'] || null
                  } : {})}
                  render={(status, rec) => (
                    <EditableField 
                    forbidden={!privilegeGuard(roles, "admin")}
                    handleSave={(newStatus) => updateStatus(rec.id, newStatus)}
                                   title="Update Status"
                                   withTitle
                                   titlePopover="Edit Status"
                                   isLoading={loadings.status || isLoading}
                                   iconClassName={b('edit')}
                                   initialValue={status}
                                   changeBlock={(props) => (
                                     <Select {...props} size="small">
                                       <Select.Option value="Disable">Disable</Select.Option>
                                       <Select.Option value="Enable">Enable</Select.Option>
                                     </Select>
                                   )}>
                      <PrintAccountState status={status} />
                    </EditableField>
                  )}
    />
    <Table.Column dataIndex="group"
                  title="Group"
                  width={150}
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'group' ? sorter.order : null}
                  {...(filters != null && groups ? {
                    filteredValue: filters['group'] || null,
                    filters: groups.map(item => ({ value: item, text: item })),
                    filterMultiple: true
                  } : {})}
                  render={(group, rec) => (
                    <EditableField 
                    forbidden={!privilegeGuard(roles, "admin")}
                    handleSave={(newGroup) => updateGroup(rec.id, newGroup)}
                                   title="Update Group"
                                   withTitle
                                   titlePopover="Edit Group"
                                   isLoading={loadings.updateGroup || isLoading}
                                   iconClassName={b('edit')}
                                   initialValue={group}
                                   changeBlock={(props) => (
                                     <GroupSelector {...props} size="small" />
                                   )}>
                        <Tag>{group}</Tag>
                    </EditableField>
                  )}
    />
    <Table.Column dataIndex="enabledJobs"
                  title="Enabled Jobs"
                  render={(enabledJobs, rec) => {
                    return (
                    <EnabledJobs
                                forbidden={!privilegeGuard(roles, "admin") || !privilegeGuard(roles, "worker_edit_enabled_jobs")}
                                field={"enabledJobs"}
                                rec={rec}
                                isLoading={loadings.enabledJobs || isLoading}
                                enabledJobs={enabledJobs}
                                iconClassName={b('edit')}
                                handleSave={updateEnabledJobs}
                    />
                    )
                  }}
    />
     {/* <Table.Column  dataIndex="redeemCorrect"
                    title="Redeem Correct"
                    align="center"
                    {...(filters != null ? {
                      filters: [
                          {text: 'Correct', value: true},
                          {text: 'Incorrect', value: false},
                      ],
                      filteredValue: filters['redeemCorrect'] || null
                  } : {})}
                  filterMultiple={false}
                    render={(value, rec) => (
                      <RedeemCorrect 
                      value={value} 
                      rec={rec} 
                      handleSave={updateRedeemCorrectAccount} 
                      field="redeemCorrect"
                      /> 
                  )}
    /> */}

<Table.Column  dataIndex="state.redeem"
                    title="Last Redeem"
                    align="center"
                  render={(_, rec) => {
                    const closestRedeem = findClosestTimestamp(rec?.state?.redeems)
                    return (
                    rec?.state?.redeems?.length > 0 ?
                    <> 
                    <Tag style={{width: '200px'}} color="orange">Last redeem was: {calculateTimeDifference(closestRedeem)} ago</Tag>
                    </>
                    :
                    <> 
                    <Tag style={{width: '200px'}} color="red">No redeems yet</Tag>
                    </>
                    
                    )
                  }}
    />
    <Table.Column  dataIndex="hangGameFlow"
                    title="Hang game flow"
                    align="center"
                    {...(filters != null ? {
                      filters: [
                          {text: 'Old', value: true},
                          {text: 'New', value: false},
                      ],
                      filteredValue: filters['hangGameFlow'] || null
                  } : {})}
                  filterMultiple={false}
                    render={(value, rec) => (
                      <RedeemCorrect 
                      value={value} 
                      rec={rec} 
                      handleSave={updateRedeemCorrectAccount}
                      field="hangGameFlow"
                      /> 
                  )}
    />
    <Table.Column dataIndex="inventoryBalanceEur"
                  title="INV Balance(EUR)"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'inventoryBalanceEur' ? sorter.order : null}
                  render={value => <PrintPrice value={value} />}
    />
    <Table.Column dataIndex="balanceEur"
                  title="Balance(EUR)"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'balanceEur' ? sorter.order : null}
                  render={value => <PrintPrice value={value} />}
    />
    <Table.Column dataIndex="points"
                  title="Points"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'points' ? sorter.order : null}
    />
    <Table.Column dataIndex="limit1ActionAmount"
                  title="2h Amount"
                  align="right"
                  render={value => <PrintPrice value={value} />}
    />
    <Table.Column dataIndex="limit2ActionAmount"
                  title="24 Amount"
                  align="right"
                  render={value => <PrintPrice value={value} />}
    />
    <Table.Column dataIndex="redeemSumEur"
                  title="Redeem Sum(EUR)"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'redeemSumEur' ? sorter.order : null}
                  render={value => <PrintPrice value={value} />}
    />
    <Table.Column dataIndex="balLimit"
                  title="Limit(EUR)"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'balLimit' ? sorter.order : null}
                  render={(value, rec) =>
                    <EditableField 
                    forbidden={!privilegeGuard(roles, "admin")}
                    handleSave={(newValue) => updateBalLimit(rec.id, newValue)}
                                   title="Update BalLimit"
                                   titlePopover="Edit BalLimit"
                                   iconClassName={b('edit')}
                                   withTitle
                                   isLoading={loadings.balLimit || isLoading}
                                   initialValue={value}
                                   changeBlock={({ onChange, ...props }) => (
                                     <Input {...props}
                                            size="small"
                                            onChange={e => onChange(e.target.value)} />
                                   )}>
                      <PrintPrice value={value} />
                    </EditableField>
                  }
    />
    <Table.Column dataIndex="driver"
                  title="Driver"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'driver' ? sorter.order : null}
                  render={(value, rec) =>
                    <EditableField 
                    forbidden={!privilegeGuard(roles, "admin")}
                    handleSave={(newDriver) => updateDriver(rec.id, newDriver)}
                                   title="Update Driver"
                                   titlePopover="Edit Driver"
                                   isLoading={loadings.driver || isLoading}
                                   iconClassName={b('edit')}
                                   withTitle
                                   initialValue={value}
                                   changeBlock={({ onChange, ...props }) => (
                                     <Input {...props}
                                            size="small"
                                            type="number"
                                            onChange={e => onChange(e.target.value)} />
                                   )}>
                      {value}
                    </EditableField>
                  }
    />
    <Table.Column dataIndex="card"
                  title="Card"
                  align="right"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'card' ? sorter.order : null}
                  render={(value, rec) =>
                      <EditableField 
                      forbidden={!privilegeGuard(roles, "admin")}
                      handleSave={(newCard) => updateCard(rec.id, newCard)}
                                     title="Update Card (Format: MasterCard:9999999999999999:99/99:999)"
                                     titlePopover="Edit Card"
                                     iconClassName={b('edit')}
                                     withTitle
                                     isLoading={loadings.card || isLoading}
                                     initialValue={value}
                                     changeBlock={({ onChange, ...props }) => (
                                         <Input {...props}
                                                size="small"
                                                onChange={e => onChange(e.target.value)} />
                                     )}>
                          {value}
                      </EditableField>
                  }
    />
    <Table.Column dataIndex="friends"
                  title="Friends"
                  align="right"
                  render={(value, rec) =>
                      <EditableField 
                      forbidden={!privilegeGuard(roles, "admin")}
                      handleSave={(newValue) => updateAccount(rec.id, {friends: JSON.parse(newValue)})}
                                     title="Update Friends"
                                     titlePopover="Edit Friends"
                                     iconClassName={b('edit')}
                                     withTitle
                                     isLoading={loadings.updateAccounts || isLoading}
                                     initialValue={JSON.stringify(value)}
                                     changeBlock={({ onChange, ...props }) => (
                                         <Input {...props}
                                                size="small"
                                                onChange={e => onChange(e.target.value)} />
                                     )}>
                          {JSON.stringify(value)}
                      </EditableField>
                  }
    />
    <Table.Column dataIndex="comment"
                  title="Comment"
                  width="250px"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'comment' ? sorter.order : null}
                  render={(value, rec) =>
                    <EditableField 
                                  forbidden={!privilegeGuard(roles, "admin")}
                                  handleSave={(newComment) => updateComment(rec.id, newComment)}
                                   title="Update Comment"
                                   titlePopover="Edit Comment"
                                   isLoading={loadings.comment || isLoading}
                                   iconClassName={b('edit')}
                                   withTitle
                                   initialValue={value}
                                   changeBlock={({ onChange, ...props }) => (
                                     <TextArea {...props}
                                               size="small"
                                               onChange={e => onChange(e.target.value)} />
                                   )}>
                      {value}
                    </EditableField>
                  }
    />
    <Table.Column dataIndex="resultCode"
                  title="Result Code"
                  sorter={sorter != null}
                  sortOrder={sorter != null && sorter.field === 'resultCode' ? sorter.order : null}
                  {...(filters != null && accountCodeFilters ? {
                    filteredValue: filters['resultCode'] || null,
                    filters: accountCodeFilters.map(item => ({ value: item, text: item })),
                    filterMultiple: true
                  } : {})}
    />
    {privilegeGuard(roles, "workers_actions") && 
    <Table.Column dataIndex="id"
                  title="Actions"
                  render={(id, rec) => <div className={b('actions')}>
                    <ChangePassword className="mr-small" accountId={id} />
                    <ChangeToken token={rec.authToken} accountId={id} />
                    <ChangeSteamId steamId={rec.steamId} accountId={id} />
                    <Button type="link" size="small" onClick={() => updateStatus(id, 'cancel-inventory')}>Cancel Inventory</Button>
                    <Button type="link" size="small" onClick={() => updateStatus(id, 'purch-inventory')}>Purch Inventory</Button>
                    <Button type="link" size="small" onClick={() => updateStatus(id, 'check-purch')}>Check Purch</Button>
                    <Button type="link" size="small" onClick={() => updateStatus(id, 'fetch-points')}>Fetch Points</Button>
                  </div>}
    />
    }
  </Table>;
}

export default AccountsTable;
