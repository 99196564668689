import { createAction, createReducer } from '@reduxjs/toolkit';
import ProxiesService from '../Services/ProxiesService';

import fec from 'front-end-common';

const { getDefaultHandler, getDefaultState } = fec.utils.reducerTools;

export const fetchProxy = createAction('proxy/get', (id) => ({
  payload: ProxiesService.fetchProxy(id),
}));


const initState = {
  proxy: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchProxy, 'proxy')
});
