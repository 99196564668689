import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import './styles.scss';
import JobStatusPicker from '../JobStatusPicker';
import { momentToUnix, unixToMoment } from '../../Utils/dates';

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function JobFilters({ className, filters, onFilter, withCodeFilter, withGiftCodeFilter }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  return <Row gutter={16} className={`${className} ${b()}`}>
    <Col span={3}>
      <span className={b('label')}>JobId</span>
      <Input value={curFilters.jobId ? curFilters.jobId[0] : null}
             className={b('item')}
             onChange={e => handleChangeFilter(e.target.value, 'jobId')}
      />
    </Col>
    <Col span={10}>
      <span className={b('label')}>Dates</span>
      <RangePicker showTime
                   value={curFilters.Dates ? curFilters.Dates.map(unixToMoment) : [null, null]}
                   className={b('item')}
                   onChange={dates => handleChangeFilter(dates ? dates.map(momentToUnix) : null, 'Dates')}
      />
    </Col>
    <Col span={4}>
      <span className={b('label')}>Status</span>
      <JobStatusPicker className={b('item')}
                       value={curFilters.status ? curFilters.status[0] : null}
                       onChange={(v) => handleChangeFilter(v, 'status') }
      />
    </Col>
    {withCodeFilter && <Col span={3}>
      <span className={b('label')}>Code</span>
      <Input className={b('item')}
             value={curFilters.code ? curFilters.code[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'code')}
      />
    </Col>}
    {withGiftCodeFilter && <Col span={3}>
      <span className={b('label')}>Gift Code</span>
      <Input className={b('item')}
             value={curFilters.giftCode ? curFilters.giftCode[0] : null}
             onChange={e => handleChangeFilter(e.target.value, 'giftCode')}
      />
    </Col>}
    <Col span={4}>
      <span className={b('label')} />
      <Button className={b('item')} type="primary" onClick={() => onFilter(curFilters)}>Filter</Button>
    </Col>
  </Row>;
}

export default JobFilters;
