import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/es/typography/Title';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchDrivers } from '../../Reducers/driver';
import DriverTable from './DriverTable';
import CreateDriver from './CreateDriver';
import './styles.scss';
import { useOutletContext } from 'react-router';
import { Result } from 'antd';
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('Driver');

function Driver() {
  const { payload, error, isLoading } = useSelector(state => state.driver.drivers, shallowEqual);
  const dispatch = useDispatch();

  const fetch = useCallback(() => dispatch(fetchDrivers()), [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const roles = useOutletContext()

  return (
    privilegeGuard(roles, "admin") ? <div>
    <Title className={b('title')} level={1}>
      Drivers
      <CreateDriver afterUpdate={fetch} />
    </Title>
    <DriverTable payload={payload}
                 error={error}
                 isLoading={isLoading}
                 fetch={fetch}
    />
  </div> :
  <Result
  status="403"
  title="403"
  subTitle="Sorry, you are not authorized to access this page."
/>
  )
}

export default Driver;
