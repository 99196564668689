import React, { useMemo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

function GroupSelector({ className, value, defaultValue, onChange, disabled }) {
  const { payload, isLoading } = useSelector(state => state.config.data, shallowEqual);

  const groups = useMemo(() => {
    try {
      return JSON.parse(payload.find(({ name }) => name === 'groups').value);
    } catch (e) {
      return [];
    }
  }, [payload]);

  return (
    <Select defaultValue={defaultValue}
            className={className}
            loading={isLoading}
            allowClear
            value={value}
            onChange={onChange}
            style={{ width: '100%', minWidth: '100px' }}
            disabled={disabled}
    >
      {groups.map(value => <Select.Option value={value}>{value}</Select.Option>)}
    </Select>
  );
}

GroupSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
};

export default GroupSelector;
