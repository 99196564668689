import React, { useCallback } from 'react';
import { Button, Drawer, Form, Input, message } from 'antd';
import fec from 'front-end-common';
import ErrorBlock from '../../../Components/ErrorBlock';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changePasswordAccount } from '../../../Reducers/accounts';

const useBoolean = fec.hooks.useBoolean;

function ChangePassword({ className, accountId }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { error, isLoading } = useSelector(state => state.accounts.changePassword, shallowEqual);
  const dispatch = useDispatch();

  const handleSuccess = useCallback(({ password }) => {
    dispatch(changePasswordAccount({ accountId, password })).then(() => {
      message.success('Password has been updated!');
      close();
    });
  }, [dispatch, accountId, close]);

  return <>
    <Button className={className} type="link" size="small" onClick={open}>Change Password</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="Change Password"
            placement="right"
            width={600}
    >
      {error && <ErrorBlock message={error.message} />}
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            { required: true, message: 'Please input new password.' },
          ]}
          label="New Password"
          name="password"
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save Password
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default ChangePassword;
