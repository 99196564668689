import React, { useEffect, useState } from 'react';
import b_ from 'b_';
import { Layout as AntLayout } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import './styles.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchAuxAccount } from '../../Reducers/accounts';

const Content = AntLayout.Content;

const b = b_.lock('Layout');



function MainLayout() {

  const { payload } = useSelector(state => state.accounts.aux, shallowEqual);
  const [roles, setRoles] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAuxAccount());
  }, [dispatch]);

  const privileges = payload?.userPrivileges

  useEffect(() => {
    if(privileges) setRoles(privileges)
  }, [privileges])

  // console.log('data', roles)

  return <AntLayout className={b()}>
    <AntLayout style={{ flexDirection: "column" }}>
      <Header className={b('header', { isCollapsed: true })} />
      <Content className={b('content', { isCollapsed: true })}>
        <Outlet context={roles} />
      </Content>
    </AntLayout>
  </AntLayout>;
}

export default MainLayout;
