import React from 'react';
import b_ from 'b_';
import Table from 'antd/es/table/Table';
import JSONTree from 'react-json-tree';
import PrintJobStatus from '../Prints/PrintJobStatus';
import pathToFileName from '../../Utils/pathToName';
import PrintDate from '../Prints/PrintDate';
import theme from '../../Utils/jsonTheme';
import Expandable from './ Expandable';
import PrintFromNow from '../Prints/PrintFromNow';
import ImageViews from '../ImageViews';
import { Link } from 'react-router-dom';
import './styles.scss';
import LinksViews from '../LinksViews';

const b = b_.lock('JobTable');

// TODO  удалить при правильной настройке nginx
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function JobsTable({ list, pagination, onChange, isLoading, selected, setSelected }) {
  return <Table bordered
                className={b()}
                scroll={{ x: 'max-content' }}
                dataSource={list}
                rowKey="id"
                onChange={onChange}
                pagination={pagination}
                loading={isLoading}
                rowSelection={{
                  selectedRowKeys: selected,
                  onChange: setSelected,
                }}
                expandable={{
                  expandedRowRender: record => <Expandable record={record} />,
                }}
  >
    <Table.Column dataIndex="id" title="ID" />
    <Table.Column dataIndex="createTs"
                  title="Created"
                  render={(create) => <PrintDate withTime date={create} />}
    />
    <Table.Column dataIndex="updateTs"
                  title="Updated"
                  render={(update, rec) => <PrintFromNow date={rec.createTs} start={update} />}
    />
    <Table.Column dataIndex="accountName"
                  title="Account Name"
                  render={(name, rec) => <Link to={`/account/${rec.accountId}`}>{name}</Link>}
    />
    <Table.Column dataIndex="status"
                  title="Status"
                  render={status => <PrintJobStatus jobStatus={status} />}
    />
    <Table.Column dataIndex="phase" title="Phase" />
    <Table.Column title="Action" dataIndex="action"
                  render={(json, rec) =>
                  <>
                    <JSONTree hideRoot theme={theme} data={json} />
                    {rec.purchaseResult && <JSONTree hideRoot theme={theme} data={rec.purchaseResult} />}
                    {rec.redeemResult && <JSONTree hideRoot theme={theme} data={rec.redeemResult} />}
                  </>
    }
    />
    <Table.Column dataIndex="resultCode"
                  title="Result Code"
                  width={300}
                  render={(resultCode, rec) =>
                      <>
                          {!rec.message && resultCode}
                          {rec.message && <JSONTree hideRoot theme={theme} data={ {[resultCode]: { "": rec.message} }} />}
                      </>
                  }

    />
    <Table.Column dataIndex="screens"
                  title="Screens"
                  render={images => (<ImageViews images={images} />)}
    />
    <Table.Column dataIndex="pages"
                  title="Pages"
                  render={pages => (<LinksViews disabled={!pages.length} pages={pages} />)}
    />
  </Table>;
}

export default JobsTable;
