import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { cancelJobsAccount, fetchAccount, fetchJobsAccount } from '../../Reducers/account';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import './styles.scss';
import useTableState from '../../Utils/useTableState';
import JobsTable from '../../Components/JobsTable';
import { Button, message } from 'antd';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import { fetchAuxAccount } from '../../Reducers/accounts';
import JobFilters from '../../Components/JobFilters';
import { fetchConfigs } from '../../Reducers/config';
import AccountsTable from "../Accounts/AccountsTable";
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('AccountPage');

function AccountPage() {

  const roles = useOutletContext()

  const dispatch = useDispatch();
  const params = useParams();

  const fetchJobs = useCallback((data) => fetchJobsAccount({ ...data, account_id: params.id }), []);
  const { fetch, ...props } = useTableState(
    state => state.account.jobs,
    fetchJobs,
  );

  const [selectedRow, setSelectedRow] = useState([]);
  const cancelLoading = useSelector(state => state.account.cancelJobs.isLoading, shallowEqual);

  const {
    payload,
    isLoading,
  } = useSelector(state => state.account.account, shallowEqual);

  useEffect(() => {
    dispatch(fetchAccount(params.id));
    dispatch(fetchAuxAccount());
    dispatch(fetchConfigs());
  }, [dispatch]);

  const cancelJobs = useCallback(() => {
    dispatch(cancelJobsAccount(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  const afterUpdate = useCallback(() => {
    dispatch(fetchAccount(params.id));
    fetch();
  }, [fetch, dispatch, params]);

  return <div>
    <Title className="mb">Account-details - {(payload && payload.login) || params.id}</Title>
    <AccountsTable 
                    privilegeGuard={privilegeGuard} 
                    roles={roles}
                    afterUpdate={afterUpdate}
                   isLoading={isLoading}
                   list={(payload && [payload]) || null}
    />
    <br/>
    <Title className={`mb ${b('title')}`}>
      Jobs
      <Button onClick={cancelJobs}
              disabled={selectedRow.length === 0}
              icon={<StopOutlined />}
              type="primary"
              className="mb-small"
              size="large"
              loading={props.isLoading || cancelLoading}
      >
        Cancel Jobs
      </Button>
    </Title>
    <JobFilters filters={props.filters} onFilter={props.onFilter} className="mb" />

    <JobsTable {...props} selected={selectedRow} setSelected={setSelectedRow} fetch={fetch} />
  </div>;
}

export default AccountPage;
