import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import { fetchAccounts, fetchAuxAccount } from '../../Reducers/accounts';
import AccountsTable from './AccountsTable';
import CreateAccount from './CreateAccount';
import UpdateAccounts from './UpdateAccounts';
import AccountGroupBalance from './AccountGroupBalanceTable';
import { useDispatch } from 'react-redux';
import DisableButton from './DisableButton';
import EnableButton from './EnableButton';
import ErrorBlock from '../../Components/ErrorBlock';
import './styles.scss';
import { fetchConfigs } from '../../Reducers/config';
import SetRedeem4GiftPhaseButton from "./SetRedeem4GiftPhaseButton";
import UploadAccounts from "./UploadAccounts";
import UploadFriends from "./UploadFriends";
import { useOutletContext } from 'react-router';
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('Accounts');

function Accounts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    sort,
    error,
    onSearch,
  } = useTableState(state => state.accounts.list, fetchAccounts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAuxAccount());
    dispatch(fetchConfigs());
  }, [dispatch]);

  const [selectedRow, setSelectedRow] = useState([]);
  // const [roles, setRoles] = useState([]);

  const roles = useOutletContext()

  // useEffect(() => {
  //   if(privileges) setRoles(privileges)
  // }, [privileges])

  console.log('props', roles)

  return <div className={b()}>
    <Title className="mb">
      Accounts
    </Title>
    {privilegeGuard(roles, "workers_view_balances") && <AccountGroupBalance className="mb" />}
    <div className={b('accountBlock')}>
      {privilegeGuard(roles, "worker_add_new") && 
      <>
        {/* <UpdateAccounts afterSuccess={fetch}/> */}
        <CreateAccount className="mr" afterSuccess={fetch} />
        <UploadAccounts afterSuccess={fetch} className="mr-small"/>
      </>
      }
      {privilegeGuard(roles, "admin") && 
      <UploadFriends afterSuccess={fetch} className="mr-small"/>
      }
      <UpdateAccounts usePrivilege={privilegeGuard} roles={roles} className="mr" afterSuccess={fetch} selected={selectedRow} />
      {privilegeGuard(roles, "worker_enable_disable") && 
      <>
      <DisableButton className="mr" selected={selectedRow} afterSuccess={fetch} />
      <EnableButton className="mr" selected={selectedRow} afterSuccess={fetch} />
      </>
    }
    </div>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Input.Search onSearch={onSearch} className="mb" size="large" />
    <AccountsTable selected={selectedRow}
                   setSelected={setSelectedRow}
                   afterUpdate={fetch}
                   list={list}
                   pagination={pagination}
                   onChange={onChange}
                   onFilter={onFilter}
                   isLoading={isLoading}
                   filters={filters}
                   sorter={sort}
                   privilegeGuard={privilegeGuard} 
                   roles={roles}
    />
  </div>;
}

export default Accounts;
