import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import accounts from './accounts';
import redeemJobs from './redeemJobs';
import otherJobs from './otherJobs';
import account from './account';
import config from './config';
import driver from './driver';
import purchaseJobs from "./purchaseJobs";
import proxies from "./proxies";
import proxy from "./proxy";

export default combineReducers({
  auth,
  accounts,
  account,
  purchaseJobs,
  redeemJobs,
  otherJobs,
  config,
  driver,
  proxies,
  proxy
});
