import React, { useEffect } from 'react';
import qs from 'qs';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authGetTokens } from '../../Reducers/auth';
import TokenService from '../../Services/TokenService';

function RedirectPage() {
  const location = useLocation();
  const query = qs.parse(location.search.replace(/^\?/, ''));
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();

  useEffect(() => {
    if (query.code) {
      TokenService.removeToken();

      dispatch(authGetTokens(query.code))
        .then(data => {
          TokenService.setTokens(data);
          navigate('/', { replace: true });
        })
        .catch(e => {
          console.log('..........', e);
          navigate('/sign-in', { replace: true });
        });
    }
  }, [dispatch]);

  if (!query.code) {
    return  redirect("/sign-in");
  }

  return <div>Wait for save token</div>;
}

export default RedirectPage;
