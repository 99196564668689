import qs from 'qs';
import TokenService from './TokenService';
import fec from 'front-end-common';

const {
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_URL: AUTH_URL,
} = process.env;

export function prepareQueryString(params) {
  const updParams = Object.keys(params)
    .filter(key => params[key] !== null)
    .reduce((acc, cur) => ({ ...acc, [cur]: params[cur] }), {});

  return qs.stringify(updParams, { encode: false, arrayFormat: 'brackets' });
}

export function parseQueryString(queryString) {
  return qs.parse(queryString, { ignoreQueryPrefix: true });
}

const API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api/steam-bo';

export default new fec.services.ApiService(API_PREFIX, TokenService, { CLIENT_ID, AUTH_URL });
