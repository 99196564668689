import { createAction, createReducer } from '@reduxjs/toolkit';
import AccountService from '../Services/AccountService';
import fec from 'front-end-common';

const { getDefaultHandler, getDefaultState } = fec.utils.reducerTools;

export const fetchAccounts = createAction('accounts/list', (params) => ({
  payload: AccountService.getList(params),
}));

export const fetchArchiveAccounts = createAction('accounts/archive-list', (params) => ({
  payload: AccountService.getArchiveAccounts(params),
}));

export const updateProxyAccount = createAction('accounts/update-proxy', (params) => ({
  payload: AccountService.changeProxy(params),
}));

export const updateDriverAccount = createAction('accounts/update-driver', (param) => ({
  payload: AccountService.changeDriver(param),
}));

export const updateCardAccount = createAction('accounts/update-card', (param) => ({
  payload: AccountService.changeCard(param),
}));
export const updatePhaseAccount = createAction('accounts/update-phase', (param) => ({
  payload: AccountService.changePhase(param),
}));

export const deArchiveAccount = createAction('accounts/de-archive', (param) => ({
  payload: AccountService.deArchiveAccount(param),
}));

export const setRedeem4GiftPhase = createAction('accounts/set-redeem-4-gift-phase', (data) => ({
  payload: AccountService.setRedeem4GiftPhase(data),
}));

export const updateLoginAccount = createAction('accounts/update-login', (param) => ({
  payload: AccountService.changeLogin(param),
}));

export const updateBalLimitAccount = createAction('accounts/update-balLimit', (param) => ({
  payload: AccountService.changeBalLimit(param),
}));

export const updatePurchLimitAccount = createAction('accounts/update-purchLimit', (param) => ({
  payload: AccountService.changePurchLimit(param),
}));

export const updateEnabledJobsAccount = createAction('accounts/update-enabledJobs', (param) => ({
  payload: AccountService.changeEnabledJobs(param),
}));

export const updateGroupAccount = createAction('accounts/update-group', (param) => ({
  payload: AccountService.changeGroup(param),
}));

export const updateRedeemCorrect = createAction('accounts/update-redeem', (param) => ({
  payload: AccountService.changeRedeemCorrect(param),
}));

export const updateCommentAccount = createAction('accounts/update-comment', (param) => ({
  payload: AccountService.changeComment(param),
}));

export const createAccount = createAction('accounts/create', (data) => ({
  payload: AccountService.createAccount(data),
}));

export const updateAccounts = createAction('accounts/updates', (data) => ({
  payload: AccountService.updateAccounts(data),
}));

export const changePasswordAccount = createAction('accounts/change-password', (data) => ({
  payload: AccountService.changePassword(data),
}));

export const changeTokenAccount = createAction('accounts/change-token', (data) => ({
  payload: AccountService.changeToken(data),
}));

export const changeSteamIdAccount = createAction('accounts/change-steam-id', (data) => ({
  payload: AccountService.changeSteamId(data),
}));

export const changeStatusAccount = createAction('accounts/change-status', (data) => ({
  payload: AccountService.changeStatus(data),
}));

export const fetchGroupBalanceAccount = createAction('accounts/fetch-group-balance', () => ({
  payload: AccountService.fetchAccountGroupBalance(),
}));

export const fetchAuxAccount = createAction('accounts/fetch-aux', () => ({
  payload: AccountService.fetchAux(),
}));

export const disableAccounts = createAction('accounts/disable', (data) => ({
  payload: AccountService.disableAccounts(data),
}));

export const enableAccounts = createAction('accounts/enable', (data) => ({
  payload: AccountService.enableAccounts(data),
}));

export const createAccounts = createAction('accounts/create-accounts', (data) => ({
  payload: AccountService.createAccounts(data),
}));

export const uploadFriends = createAction('accounts/upload-friends', (data) => ({
  payload: AccountService.uploadFriends(data),
}));

const initState = {
  list: getDefaultState(),
  archiveAccounts: getDefaultState(),
  updateProxy: getDefaultState(),
  updateLogin: getDefaultState(),
  updateBalLimit: getDefaultState(),
  updatePurchLimit: getDefaultState(),
  updateEnabledJobs: getDefaultState(),
  updateDriver: getDefaultState(),
  updateCard: getDefaultState(),
  updatePhase: getDefaultState(),
  deArchiveAccount: getDefaultState(),
  setRedeem4GiftPhase: getDefaultState(),
  changeStatus: getDefaultState(),
  updateComment: getDefaultState(),
  create: getDefaultState(),
  updateAccounts: getDefaultState(),
  changePassword: getDefaultState(),
  updateGroup: getDefaultState(),
  updateRedeem: getDefaultState(),
  changeToken: getDefaultState(),
  changeSteamId: getDefaultState(),
  groupBalance: getDefaultState(),
  aux: getDefaultState(),
  disableAccounts: getDefaultState(),
  enableAccounts: getDefaultState(),
  createAccounts: getDefaultHandler(),
  uploadFriends: getDefaultHandler(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccounts, 'list'),
  ...getDefaultHandler(fetchArchiveAccounts, 'archiveAccounts'),
  ...getDefaultHandler(updateProxyAccount, 'updateProxy'),
  ...getDefaultHandler(updateLoginAccount, 'updateLogin'),
  ...getDefaultHandler(updateBalLimitAccount, 'updateBalLimit'),
  ...getDefaultHandler(updatePurchLimitAccount, 'updatePurchLimit'),
  ...getDefaultHandler(updateEnabledJobsAccount, 'updateEnabledJobs'),
  ...getDefaultHandler(updateDriverAccount, 'updateDriver'),
  ...getDefaultHandler(updateCardAccount, 'updateCard'),
  ...getDefaultHandler(updatePhaseAccount, 'updatePhase'),
  ...getDefaultHandler(updateCommentAccount, 'updateComment'),
  ...getDefaultHandler(createAccount, 'create'),
  ...getDefaultHandler(updateAccounts, 'updateAccounts'),
  ...getDefaultHandler(changePasswordAccount, 'changePassword'),
  ...getDefaultHandler(changeTokenAccount, 'changeToken'),
  ...getDefaultHandler(changeSteamIdAccount, 'changeSteamId'),
  ...getDefaultHandler(changeStatusAccount, 'changeStatus'),
  ...getDefaultHandler(fetchGroupBalanceAccount, 'groupBalance'),
  ...getDefaultHandler(fetchAuxAccount, 'aux'),
  ...getDefaultHandler(disableAccounts, 'disableAccounts'),
  ...getDefaultHandler(updateGroupAccount, 'updateGroup'),
  ...getDefaultHandler(updateRedeemCorrect, 'updateRedeem'),
  ...getDefaultHandler(createAccounts, 'createAccounts'),
  ...getDefaultHandler(uploadFriends, 'uploadFriends')
});
