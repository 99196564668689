import ApiService from "./ApiService";


export default {
    getProxies () {
        return ApiService.get('/proxies')
    },
    editProxies (params) {
        return ApiService.put('/proxies', params)
    },
    createProxies (params) {
        return ApiService.post('/proxies', params)
    },
    fetchProxy(id) {
        return ApiService.get(`/proxy/${id}`);
    },
}
