import React, {useCallback, useReducer, useState} from 'react';
import b_ from 'b_';
import {Button, Checkbox, Drawer, Form, Input, message, Popconfirm, Select, Switch} from 'antd';
import fec from 'front-end-common';
import GroupSelector from '../Selectors/GroupSelector';
import TextArea from 'antd/lib/input/TextArea';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import {updateAccounts} from '../../../Reducers/accounts';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';
import SelectEnabledJobs from '../../../Components/SelectEnabledJobs';
import RedeemCorrect from '../../../Components/RedeemCorrect';
import { PoweroffOutlined } from '@ant-design/icons';

const useBoolean = fec.hooks.useBoolean;

const b = b_.lock('CreateAccount');

function UpdateAccounts({className, afterSuccess, selected, usePrivilege, roles}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector(state => state.accounts.updateAccounts.isLoading, shallowEqual);

  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);

  const [resetFields, setResetFields] = useState([]);

  const [disabledRedeemCorrect, setDisabledRedeemCorrect] = useState(true);
  const [disabledGameFlow, setDisabledGameFlow] = useState(true);

  const toggle = () => {
    setDisabledRedeemCorrect(!disabledRedeemCorrect);
  };

  const toggleFlow = () => {
    setDisabledGameFlow(!disabledGameFlow);
  };

  const [checked, setChecked] = useState(true);

  const toggleChecked = () => {
    setChecked(!checked);
  };


  const changeResetFields = useCallback((field) => {
    if (resetFields.includes(field)) {
      return setResetFields(resetFields.filter(v => v !== field));
    }

    setResetFields([...resetFields, field]);
  }, [setResetFields, resetFields]);

  const handleSuccess = useCallback((formFields) => {
    console.log('form fields', formFields)
    console.log('reset fields', resetFields)
    const someExist = Object.keys(formFields).some(key => formFields[key]) || formFields.redeemCorrect === false || formFields.hangGameFlow === false;

      if (!someExist) {
        return message.error('Please input one of field');
      }

    const fields = {...formFields, ...resetFields.reduce((acc, cur) => ({...acc, [cur]: ''}), {})};

    const data = {accountIds: selected, ...fields};
    dispatch(updateAccounts(data)).then(() => {
      close();
      afterSuccess();
      message.success('Accounts has been updated!');
      form.resetFields();
    });
  }, [close, afterSuccess, dispatch, selected, resetFields, form]);

  const error = useSelector(state => state.accounts.updateAccounts.error, shallowEqual);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  return (
    <>
      <Button type="primary"
              // disabled={!selected.length}
              size="large"
              className={className}
              icon={<EditFilled/>}
              onClick={open}
      >
        Update {selected.length} accounts
      </Button>
      <Drawer visible={isOpen}
              title={`Update Accounts: ${selected.length}`}
              width={600}
              closable
              destroyOnClose
              placement="right"
              onClose={close}
      >
        {error && <ErrorBlock message={error.message}/>}
        <Form onFieldsChange={forceUpdate} form={form} layout="vertical" className={b()} onFinish={handleSuccess}>
        {usePrivilege(roles, "worker_edit_enabled_jobs") && <Form.Item
            label="Enabled Jobs"
            hasFeedback={form.getFieldValue('enabledJobs')}
            validateStatus="success"
            name="enabledJobs"
          >
            <SelectEnabledJobs disabled={resetFields.includes('enabledJobs')}/>
          </Form.Item>
        }

          <Form.Item
            label="Balance limit"
            name="balLimit"
          >
            <Input type="number"/>
          </Form.Item>

        {usePrivilege(roles, "admin") && 
        <>
          <Form.Item
            label="Group"
            hasFeedback={form.getFieldValue('group')}
            validateStatus="success"
            name="group"
          >
            <GroupSelector disabled={resetFields.includes('group')} placeholder="Select group"/>
          </Form.Item>
          <Form.Item
            label="Phase"
            hasFeedback={form.getFieldValue('phase')}
            validateStatus="success"
            name="phase"
          >
            <Select size="small" dropdownStyle={{minWidth: "15ch"}}>
              <Select.Option value="Redeem">Redeem</Select.Option>
            </Select>
          </Form.Item>
          
          <Form.Item
            label={<div>
              <span className="mr">Driver</span>
              <span className="mr-small">Reset field</span>
              <Checkbox checked={resetFields.includes('driver')}
                        onChange={() => changeResetFields('driver')}
              />
            </div>}
            name="driver"
            hasFeedback={form.getFieldValue('driver')}
            validateStatus="success"
          >
            <Input disabled={resetFields.includes('driver')}/>
          </Form.Item>
          <Form.Item
            label={<div>
              <span className="mr">Comment</span>
              <span className="mr-small">Reset field</span>
              <Checkbox checked={resetFields.includes('comment')}
                        onChange={() => changeResetFields('comment')}
              />
            </div>}
            name="comment"
            hasFeedback={form.getFieldValue('comment')}
            validateStatus="success"
          >
            <TextArea disabled={resetFields.includes('comment')}/>
          </Form.Item>
          <div style={{marginBottom: '8px'}}>
          {!disabledRedeemCorrect && 
          <Form.Item 
            name="redeemCorrect"
            label="Redeem Correct"
            initialValue={false}
            hasFeedback={form.getFieldValue('redeemCorrect')}
            validateStatus="success"
            >
            <Switch />
          </Form.Item>
        }
          <Button type="secondary" onClick={toggle} icon={<PoweroffOutlined />} > Toggle Redeem Correct </Button>
          </div>
          <div style={{marginBottom: '8px'}}>
          {!disabledGameFlow && 
          <Form.Item 
            name="hangGameFlow"
            label={!checked ? 'Old Game Flow' : 'New Game Flow'}
            initialValue={false}
            hasFeedback={form.getFieldValue('hangGameFlow')}
            validateStatus="success"
            >
            <Switch onClick={toggleChecked} />
          </Form.Item>
        }
          <Button type="secondary" onClick={toggleFlow} icon={<PoweroffOutlined />} > Toggle Game Flow </Button>
          </div>
          </>
          
}
          <Form.Item>
            <Popconfirm
              title={`Are you sure update ${selected.length} task?`}
              onConfirm={form.submit}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={isLoading} type="primary">
                Submit
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateAccounts;
