import React, { useRef } from 'react';
import b_ from 'b_';
import { Button, Drawer, message, Upload } from 'antd';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { uploadFriends } from '../../../Reducers/accounts';
import './styles.scss';
import fec from "front-end-common";

const b = b_.lock('UploadFriends');
const useBoolean = fec.hooks.useBoolean;

function UploadFriends({ className, afterSuccess }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { current: form } = useRef(new FormData());
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(state => state.accounts.uploadFriends, shallowEqual);

  const handleSubmit = () => {
    if (!form.has('file')) {
      return message.error('Please fill the form!');
    }

    dispatch(uploadFriends(form))
      .then(() => {
      message.success('Friends has been uploaded!');
      afterSuccess();
      close();
    })
  };

  const beforeUpload = (file) => {
    form.delete('file');
    form.append('file', file);
    return false;
  };

  const onRemove = () => {
    form.delete('file');
  };

  return (<>
    <Button size="large" type="primary" onClick={open} className={className} icon={<UploadOutlined />}>
      Upload Friends
    </Button>
    <Drawer visible={isOpen} title="Upload Friends" width={600} closable placement="right" onClose={close}>
      {error && <ErrorBlock className="mb" message={error.message || error} />}
      <div className={b('line')}>
        <b>Friends</b>
        <Upload accept=".csv"
                onRemove={onRemove}
                beforeUpload={beforeUpload}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </div>
      <div className={b('line')}>
        <Button loading={isLoading} type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Drawer>
  </>);
}

export default UploadFriends;
