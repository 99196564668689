import {createAction, createReducer} from "@reduxjs/toolkit";
import ProxiesService from "../Services/ProxiesService";

import fec from 'front-end-common';

const { getDefaultHandler, getDefaultState } = fec.utils.reducerTools;


export const getProxies = createAction('/proxies', () => ({
    payload: ProxiesService.getProxies()
}))
export const changeProxies = createAction('/proxies-edit', (params) => ({
    payload: ProxiesService.editProxies(params)
}))
export const createProxies = createAction('/proxies-create', (params) => ({
    payload: ProxiesService.createProxies(params)
}))

const initialState = {
    proxies: getDefaultState(),
    createProxies: getDefaultState(),
    editProxies: getDefaultState(),
}

export default  createReducer(initialState, {
    ...getDefaultHandler(getProxies, 'proxies'),
    ...getDefaultHandler(createProxies, 'createProxies'),
    ...getDefaultHandler(changeProxies, 'editProxies')
})
