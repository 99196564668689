import { createAction, createReducer } from '@reduxjs/toolkit';
import AccountService from '../Services/AccountService';
import RedeemJobService from '../Services/RedeemJobService';
import fec from 'front-end-common';

const { getDefaultHandler, getDefaultState } = fec.utils.reducerTools;

export const fetchAccount = createAction('account/get', (id) => ({
  payload: AccountService.fetchAccount(id),
}));

export const updateDriverAccount = createAction('account/update-driver', (param) => ({
  payload: AccountService.changeDriver(param),
}));

export const updateCardAccount = createAction('account/update-card', (param) => ({
  payload: AccountService.changeCard(param),
}));
export const updateGroupAccount = createAction('accounts/update-group', (param) => ({
  payload: AccountService.changeGroup(param),
}));

export const updateCommentAccount = createAction('account/update-comment', (param) => ({
  payload: AccountService.changeComment(param),
}));

export const changeStatusAccount = createAction('account/change-status', (data) => ({
  payload: AccountService.changeStatus(data),
}));

export const fetchJobsAccount = createAction('account/jobs', (data) => ({
  payload: AccountService.getJobs(data),
}));

export const updateLoginAccount = createAction('account/update-login', (param) => ({
  payload: AccountService.changeLogin(param),
}));

export const updateBalLimitAccount = createAction('account/update-balLimit', (param) => ({
  payload: AccountService.changeBalLimit(param),
}));

export const updateEnabledJobsAccount = createAction('account/update-enabledJobs', (param) => ({
  payload: AccountService.changeEnabledJobs(param),
}));

export const cancelJobsAccount = createAction('account/cancel-jobs', (data) => ({
  payload: RedeemJobService.cancel(data),
}));

const initState = {
  account: getDefaultState(),
  updateProxy: getDefaultState(),
  updateDriver: getDefaultState(),
  updateCard: getDefaultState(),
  changeStatus: getDefaultState(),
  updateComment: getDefaultState(),
  updateLogin: getDefaultState(),
  updateBalLimit: getDefaultState(),
  updatePurchLimit: getDefaultState(),
  updateEnabledJobs: getDefaultState(),
  jobs: getDefaultState(),
  cancelJobs: getDefaultState(),
  updateGroup: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccount, 'account'),
  ...getDefaultHandler(updateDriverAccount, 'updateDriver'),
  ...getDefaultHandler(updateCardAccount, 'updateCard'),
  ...getDefaultHandler(updateCommentAccount, 'updateComment'),
  ...getDefaultHandler(changeStatusAccount, 'changeStatus'),
  ...getDefaultHandler(fetchJobsAccount, 'jobs'),
  ...getDefaultHandler(cancelJobsAccount, 'cancelJobs'),
  ...getDefaultHandler(updateLoginAccount, 'updateLogin'),
  ...getDefaultHandler(updateBalLimitAccount, 'updateBalLimit'),
  ...getDefaultHandler(updateEnabledJobsAccount, 'updateEnabledJobs'),
  ...getDefaultHandler(updateGroupAccount, 'updateGroup'),
});
