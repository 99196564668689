import ApiService from './ApiService';

export default {
  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/accounts', updatedParams);
  },
  getArchiveAccounts({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/archive_accounts', updatedParams);
  },
  changeProxy(params) {
    const { id, proxy } = params;
    return ApiService.post('/account/update', { accountIds: [id], proxy });
  },
  changeEnabledJobs(data) {

    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/account/update', updatedData);
  },
  changeGroup({ id, group }) {
    return ApiService.post('/account/update', { accountIds: [id], group });
  },
  changeRedeemCorrect(data) {
    return ApiService.post('/account/update', data);
  },
  changeBalLimit(params) {
    const { id, balLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], balLimit });
  },
  changePurchLimit(params) {
    const { id, purchLimit } = params;
    return ApiService.post('/account/update', { accountIds: [id], purchLimit });
  },
  changeLogin(params) {
    const { id, login } = params;
    return ApiService.post('/account/update', { accountIds: [id], login });
  },
  changeDriver(params) {
    const { id, driver } = params;
    return ApiService.post('/account/update', { accountIds: [id], driver });
  },
  changeCard(params) {
    const { id, card } = params;
    return ApiService.post('/account/update', { accountIds: [id], card });
  },
  changePhase(params) {
    const { id, phase } = params;
    return ApiService.post('/job/set_phase', { workerIds: [id], phase });
  },
  setRedeem4GiftPhase(params) {
    const { ids } = params;
    return ApiService.post('/job/set_redeem_4_gift_phase', { workerIds: ids });
  },
  deArchiveAccount(params) {
    const { id, phase } = params;
    return ApiService.post('/worker/de_archive', { workerIds: [id], phase });
  },
  changeComment(params) {
    const { id, comment } = params;
    return ApiService.post('/account/update', { accountIds: [id], comment });
  },
  createAccount(data) {
    return ApiService.put('/account', data);
  },
  updateAccounts(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/account/update', updatedData);
  },
  changePassword(data) {
    return ApiService.post('/account/set_password', data);
  },
  changeToken(data) {
    return ApiService.post('/account/update', data);
  },
  changeSteamId(data) {
    return ApiService.post('/account/update', data);
  },
  changeStatus(data) {
    const { id, action } = data;
    return ApiService.post('/job/no_arg', { action, accountIds: [id] });
  },
  fetchAccountGroupBalance() {
    return ApiService.get('/group_balance').then((data = []) => {
      const all = data.reduce((acc, cur) => {
        acc.allBal += cur.allBal;
        acc.allGiftBal += cur.allGiftBal;
        acc.allCnt += cur.allCnt;
        acc.allLimit += cur.allLimit;
        acc.enBal += cur.enBal;
        acc.enGiftBal += cur.enGiftBal;
        acc.enCnt += cur.enCnt;
        acc.enLimit += cur.enLimit;
        acc.disBal += cur.disBal;
        acc.disGiftBal += cur.disGiftBal;
        acc.disCnt += cur.disCnt;
        acc.disLimit += cur.disLimit;
        acc.invBal += cur.invBal;
        acc.invGiftBal += cur.invGiftBal;
        acc.invCnt += cur.invCnt;
        acc.invLimit += cur.invLimit;
        acc.hasCardBal += cur.hasCardBal;
        acc.hasCardCnt += cur.hasCardCnt;
        acc.hasCardLimit += cur.hasCardLimit;
        return acc;
      }, {
        group: 'ALL',
        allBal: 0,
        allGiftBal: 0,
        allCnt: 0,
        allLimit: 0,
        enBal: 0,
        enGiftBal: 0,
        enCnt: 0,
        enLimit: 0,
        disBal: 0,
        disGiftBal: 0,
        disCnt: 0,
        disLimit: 0,
        invBal: 0,
        invGiftBal: 0,
        invCnt: 0,
        invLimit: 0,
        hasCardBal: 0,
        hasCardCnt: 0,
        hasCardLimit: 0,
        purchaseGiftEnabled: 0,
        purchaseGiftCnt: 0,
        purchaseGiftBal: 0,
        redeem4GiftCnt: 0,
        redeem4GiftBal: 0,
        purchaseSelfCnt: 0,
        purchaseSelfBal: 0,
        redeem4SelfCnt: 0,
        redeem4SelfBal: 0,
        balToAll: 1,
      });

      const updData = data.map(v => ({ ...v, balToAll: v.allBal / all.allBal }));
      updData.push(all);

      return updData;
    });
  },
  fetchAux() {
    return ApiService.get('/accounts/aux');
  },
  fetchAccount(id) {
    return ApiService.get('/account', { account_id: id });
  },
  getJobs(data) {
    return ApiService.get('/jobs', data);
  },
  disableAccounts(data) {
    return ApiService.post('/job/no_arg', data);
  },
  enableAccounts(data) {
    return ApiService.post('/job/no_arg', data);
  },
  async createAccounts(data) {
    const options = {
      headers: {
        // 'Content-type': undefined,
      },
      body: data,
    }

    return ApiService.call('/accounts', 'POST', options);
  },
  async uploadFriends(data) {
    const options = {
      headers: {
        // 'Content-type': undefined,
      },
      body: data,
    }

    return ApiService.call('/accounts/friends', 'POST', options);
  }
};
