import { createAction, createReducer } from '@reduxjs/toolkit';
import PurchaseJobService from '../Services/PurchaseJobService';
import fec from 'front-end-common';

const { getDefaultHandler, getDefaultState } = fec.utils.reducerTools;

export const fetchPurchaseJobs = createAction('purchaseJobs/list', (params) => ({
  payload: PurchaseJobService.getList(params),
}));

export const cancelPurchaseJobs = createAction('purchaseJobs/cancel', (ids) => ({
  payload: PurchaseJobService.cancel(ids),
}));

export const createPurchaseJob = createAction('purchaseJobs/create', (data) => ({
  payload: PurchaseJobService.purchase(data),
}));


const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
  create: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchPurchaseJobs, 'list'),
  ...getDefaultHandler(cancelPurchaseJobs, 'cancel'),
  ...getDefaultHandler(createPurchaseJob, 'create')
});
