import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import {Button, Result, message} from 'antd';
import Title from 'antd/lib/typography/Title';
import useTableState from '../../Utils/useTableState';
import {cancelPurchaseJobs, fetchPurchaseJobs} from '../../Reducers/purchaseJobs';
import JobsTable from '../../Components/JobsTable';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import StopOutlined from '@ant-design/icons/lib/icons/StopOutlined';
import JobFilters from '../../Components/JobFilters';
import './styles.scss';
import CreatePurchaseJob from "./CreateJob";
import {fetchConfigs} from "../../Reducers/config";
import { useOutletContext } from 'react-router';
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('PurchaseJob');

function PurchaseJob() {
  const {fetch, onFilter, error, ...props} = useTableState(state => state.purchaseJobs.list, fetchPurchaseJobs);
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();

  const cancelLoading = useSelector(state => state.purchaseJobs.cancel.isLoading, shallowEqual);

  const cancelJobs = useCallback(() => {
    dispatch(cancelPurchaseJobs(selectedRow)).then(() => {
      message.success('Jobs has been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const roles = useOutletContext()

  return (
    privilegeGuard(roles, "purch_jobs_view") || privilegeGuard(roles, "admin") ?
    <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Purchase Jobs
      {privilegeGuard(roles, "admin") && 
      <div>
        <CreatePurchaseJob className="mr" afterSuccess={fetch}/>
        <Button onClick={cancelJobs}
                disabled={selectedRow.length === 0}
                icon={<StopOutlined/>}
                type="primary"
                size="large"
                loading={props.isLoading || cancelLoading}
        >
          Cancel Jobs
        </Button>
      </div>
}
    </Title>
    <JobFilters filters={props.filters}
                onFilter={onFilter}
                className="mb"
                withCodeFilter={false}
                withGiftCodeFilter={true}/>

    <JobsTable {...props} selected={selectedRow} setSelected={setSelectedRow}/>
  </div> :
  <Result
  status="403"
  title="403"
  subTitle="Sorry, you are not authorized to access this page."
/> 
  )
}

export default PurchaseJob;
