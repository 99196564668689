import React, {useEffect} from 'react';
import {Input, Table} from 'antd';
import b_ from 'b_';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import './styles.scss';
import DeArchive from "../DeArchive";
import Title from "antd/lib/typography/Title";
import AccountsTable from "../AccountsTable";
import useTableState from "../../../Utils/useTableState";
import {fetchArchiveAccounts, fetchAuxAccount} from "../../../Reducers/accounts";
import ErrorBlock from "../../../Components/ErrorBlock";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchConfigs} from "../../../Reducers/config";
import { useOutletContext } from 'react-router';
import privilegeGuard from '../../../Utils/privilegeGuard';

const b = b_.lock('AccountsTable');

function ArchiveAccounts() {

  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    filters,
    sort,
    error,
    onSearch,
    fetch
  } = useTableState(state => state.accounts.archiveAccounts, fetchArchiveAccounts);

  const  groups = useSelector(
    (state) => {
      if (!state.config.data.payload) return null;

      try {
        let groups = state.config.data.payload.find(({ name }) => name === 'groups');
        groups = groups ? JSON.parse(groups.value) : null;

        return groups;
      } catch (e) {
        return null ;
      }
    },
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  const roles = useOutletContext()

  return <div className={b()}>
    <Title className="mb">
      Archive Accounts
    </Title>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Input.Search onSearch={onSearch} className="mb" size="large" />
    <Table bordered
           className={b()}
           scroll={{x: 'max-content'}}
           dataSource={list}
           onChange={onChange}
           pagination={pagination}
           loading={isLoading}
           onFilter={onFilter}
           rowClassName={() => b('lock')}
           rowKey="id"
    >
      <Table.Column dataIndex="id"
                    title="ID"
                    sorter
                    sortOrder={sort.field === 'id' ? sort.order : null}
      />
      <Table.Column dataIndex="login"
                    title="Login"
                    sorter
                    sortOrder={sort.field === 'login' ? sort.order : null}
      />
      <Table.Column dataIndex="group"
                    title="Group"
                    width={150}
                    sorter
                    sortOrder={sort.field === 'group' ? sort.order : null}
                    {...(groups ? {
                      filteredValue: filters['group'] || null,
                      filters: groups.map(item => ({ value: item, text: item })),
                      filterMultiple: true
                    } : {})}

      />
      <Table.Column dataIndex="balanceEur"
                    title="Balance(EUR)"
                    align="right"
                    sorter
                    sortOrder={sort.field === 'balanceEur' ? sort.order : null}
                    render={value => <PrintPrice value={value}/>}
      />
      <Table.Column dataIndex="giftBalanceEur"
                    title="Gift Balance(EUR)"
                    align="right"
                    sorter
                    sortOrder={sort.field === 'giftBalanceEur' ? sort.order : null}
                    render={value => <PrintPrice value={value}/>}
      />
      <Table.Column dataIndex="comment"
                    title="Comment"
                    sorter
                    sortOrder={sort.field === 'comment' ? sort.order : null}
      />
      {privilegeGuard(roles, "admin") && 
      <Table.Column dataIndex="id"
                    title="Actions"
                    render={(id, rec) => <div className={b('actions')}>
                      <DeArchive accountId={id} afterUpdate={fetch}/>
                    </div>}
      />
      }
    </Table>;
  </div>;
}

export default ArchiveAccounts;
