import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Modal, Button, List } from 'antd';
import frc from 'front-end-common';

const useBoolean = frc.hooks.useBoolean;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function LinksViews({ disabled, pages }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  return <>
    <Button
      disabled={disabled}
      type="primary"
      onClick={open}
      icon={<RightOutlined />}
    >
      Show ({pages?.length})
    </Button>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} height={"90vh"} centered style={{maxWidth: "500px"}} >
      <List
        size="small"
        bordered
        style={{ marginRight: "40px" }}
        dataSource={pages}
        renderItem={(item) => <List.Item key={item}><a href={BACKEND_URL + item}>{item}</a></List.Item>}
      />
    </Modal>
  </>;
}

export default LinksViews;
