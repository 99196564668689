import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import createStore from './Boot/createStore';
// import routes from './Boot/routes';
import TokenService from './Services/TokenService';
import { authSetIsAuthenticated } from './Reducers/auth';
import './index.css';
import App from './Boot/App';

const store = createStore({});

if (TokenService.hasToken()) {
  store.dispatch(authSetIsAuthenticated(true));
}

TokenService.subscribe(function (isAuthenticated) {
  store.dispatch(authSetIsAuthenticated(isAuthenticated));
});

const AppRouter = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
// ReactDOM.render(<AppRouter />, document.getElementById('root'));

root.render(<AppRouter />);

// const AppRouter = () => {
//   return <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         {renderRoutes(routes)}
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>;
// };

// ReactDOM.render(<AppRouter />, document.getElementById('root'));
