import React from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Tooltip } from 'antd'


const RedeemCorrect = ({ value, rec, handleSave, field }) => {

  const handleUpdateRedeemCorrect = (enabled) => {
    const params = {
        accountIds: [
            rec?.id
        ],
        [field]: enabled
    }

  console.log('params', params)

   return handleSave(params)
}

  return (
          <Tooltip title={value ? 'Switch to new game flow' : 'Switch to old game flow'}>
            <Switch
                    size="small"
                    className={value ? 'switch__green' : 'switch__red'}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked = {value}
                    onChange={(e) => handleUpdateRedeemCorrect(e)}
                    />
            </Tooltip>
  )
}

export default RedeemCorrect
