import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import b_ from "b_";
import './styles.scss';
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { history } from "../../../Boot/createStore";
import { links } from "../../../Boot/routes";
import Logout from '../../Logout';
import fec from 'front-end-common';

const b = b_.lock('Header')

const AntHeader = Layout.Header;
const { SubMenu } = Menu;
const { Sider } = Layout;

const IconType = ({ state }) => {
  return (
    <Button type="primary">
      {state ? <MenuUnfoldOutlined style={{ fontSize: 20 }} /> : <MenuFoldOutlined style={{ fontSize: 20 }} />}
    </Button>
  )
}

const platforms = fec.utils.platformsData;

console.log('platforms', platforms)

// const platforms = [
//   {
//     key: "steam",
//     link: "https://steam.hoteos.net",
//     name: "Steam"
//   },
//   {
//     key: "resell",
//     link: "https://resell.hoteos.net",
//     name: "Resell"
//   },
//   {
//     key: "blizzard",
//     link: "https://blizzard.hoteos.net",
//     name: "Blizzard"
//   },
//   {
//     key: "amz",
//     link: "https://amz.hoteos.net",
//     name: "Amazon"
//   },
//   {
//     key: "xbox",
//     link: "https://paa.hoteos.net",
//     name: "Xbox"
//   },
//   {
//     key: "paxful",
//     link: "https://paxful-dashboard.hoteos.net",
//     name: "Paxful"
//   }
// ]

function Header({ className }) {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  const [icon, setIcon] = useState(false)
  const path = links.filter((link) => history.location.pathname === link.href)

  return (
    <AntHeader className={`${b()} ${className}`} router={{ location, navigate, params }} >
      <Sider width="63px" className="Menu">
        <Menu
          mode="vertical"
          forceSubMenuRender={false}
          defaultSelectedKeys={(path.length && [path[0].href]) || ''}
          defaultOpenKeys={(path.length && [path[0].href]) || ''}
          onOpenChange={() => setIcon(!icon)}
          triggerSubMenuAction={"click"}
          style={{
            borderRight: 0,
          }}
        >
          <SubMenu
            key="menu"
            style={{ fontSize: 15 }}
            title={<IconType state={icon} />}
          >
            {links.map((link) => (
              <Menu.Item key={link.href}  >
                <Link to={link.href} >
                  {link.icon}
                  <span>{link.title}</span>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Sider>
      <Menu
        defaultSelectedKeys={['steam']}
        mode="horizontal"
        className="Nav"
      >
        {platforms?.map((link) => (
         <Menu.Item key={link.key}>
          <a href={link.link}>{link.name}</a>
        </Menu.Item>
        ))}
        {/* <Menu.Item key="resell">
          <a href="https://resell.hoteos.net">Resell</a>
        </Menu.Item>
        <Menu.Item key="blizzard">
          <a href="https://blizzard.hoteos.net">Blizzard</a>
        </Menu.Item>
        <Menu.Item key="amz">
          <a href="https://amz.hoteos.net">Amazon</a>
        </Menu.Item>
        <Menu.Item key="xbox">
          <a href="https://paa.hoteos.net">Xbox</a>
        </Menu.Item>
        <Menu.Item key="paxful">
          <a href="https://paxful-dashboard.hoteos.net/">Paxful</a>
        </Menu.Item> */}
      </Menu>
      <div className="Logout">
        <Logout />
      </div>
    </AntHeader>
  );
}

export default React.memo(Header);
