import React, { useCallback, useMemo, useState } from 'react';
import b_ from 'b_';
import { Button, Checkbox, Drawer, Form, Input, Switch, message } from 'antd';
import fec from 'front-end-common';
import GroupSelector from '../Selectors/GroupSelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createAccount } from '../../../Reducers/accounts';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';

const useBoolean = fec.hooks.useBoolean;
const b = b_.lock('CreateAccount');

function CreateAccount({ className, afterSuccess }) {
  const dispatch = useDispatch();
  const aux = useSelector(state => state.accounts.aux.payload, shallowEqual);
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const [checked, setChecked] = useState(true);

  const toggleChecked = () => {
    setChecked(!checked);
  };

  const defaultValues = useMemo(() => ({
    balLimit: aux ? aux.limitDefault : undefined,
    purchLimit: aux ? aux.purchLimitDefault : undefined,
  }), [aux]);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const handleSuccess = useCallback((data) => {
    dispatch(createAccount(data)).then(() => {
      close();
      afterSuccess();
      message.success('Account has been added!');
    });
  }, [close, afterSuccess, dispatch]);

  const error = useSelector(state => state.accounts.create.error, shallowEqual);

  return (
    <>
      <Button type="primary"
              size="large"
              className={className}
              icon={<PlusOutlined />}
              onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            rules={[
              { required: true, message: 'Please input account login.' },
            ]}
            label="Login"
            name="login"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input password.' },
            ]}
            label="Password"
            name="password"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input token.' },
            ]}
            label="Token"
            name="authToken"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            rules={[
              { required: false, message: 'Please input steamId.' },
            ]}
            label="Steam Id"
            name="steamId"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: false, message: 'Please select group.' },
            ]}
            label="Group:"
            name="group"
          >
            <GroupSelector />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: 'Please input limit.' },
            ]}
            label="Limit:"
            name="balLimit"
            initialValue={defaultValues.balLimit}
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Comment:"
            name="comment"
          >
            <TextArea />
          </Form.Item>
          <Form.Item 
            valuePropName="checked"
            name="redeemCorrect"
            initialValue={false}
            >
            <Checkbox >Redeem Correct</Checkbox>
          </Form.Item>
          <Form.Item 
            valuePropName="checked"
            name="hangGameFlow"
            initialValue={false}
            >
            <Checkbox onClick={toggleChecked}>{!checked ? 'Old Game Flow' : 'New Game Flow'}</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateAccount;
