import React, { useCallback, useEffect } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { List, Result } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../Components/ErrorBlock';
import { fetchConfigs } from '../../Reducers/config';
import ConfigItem from './ConfigItem';
import './styles.scss';
import { useOutletContext } from 'react-router';
import privilegeGuard from '../../Utils/privilegeGuard';

const b = b_.lock('Config');

function Config() {
  const { data, update } = useSelector(state => state.config, shallowEqual);
  const dispatch = useDispatch();

  const fetch = useCallback(() => {
    dispatch(fetchConfigs());
  }, [dispatch]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // console.log('data', data)

  const roles = useOutletContext()

  return (
  privilegeGuard(roles, "CONFIG_VIEW") ? <>
    <Title className="mb">Config</Title>
    {data.error && <ErrorBlock className="mb" message={data.error.message} />}
    <List loading={data.isLoading || update.isLoading}
          dataSource={data.payload || []}
          bordered
          className={b('list')}
          renderItem={item => (
            <List.Item className={b('listItem')}>
              <ConfigItem fetch={fetch} item={item} />
            </List.Item>
          )}
    />
  </> :
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
  />
  )
}

export default Config;
