import React, { useEffect } from 'react';
import b_ from 'b_';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Title from 'antd/lib/typography/Title';
import { Table } from 'antd';
import { fetchGroupBalanceAccount } from '../../../Reducers/accounts';
import './styles.scss';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import ErrorBlock from '../../../Components/ErrorBlock';
import PrintPercent from '../../../Components/Prints/PrintPercent';

const b = b_.lock('AccountGroupBalance');

function AccountGroupBalance({ className }) {
  const { payload, isLoading, error } = useSelector(state => state.accounts.groupBalance, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGroupBalanceAccount());
  }, [dispatch]);

  // console.log('payload', payload)

  return (<div className={className}>
    <Title level={2} className="mb">
      Account Group Balance
    </Title>
    {error && <ErrorBlock className="mb" message={error.message} description={error.description} />}
    <Table bordered
           dataSource={payload}
           loading={isLoading}
           rowKey="country"
           className={b('table')}
           pagination={false}
    >
      <Table.Column title="Group" dataIndex="group" />
      <Table.Column title="All"
                    align="right"
                    dataIndex="allBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={bal} currency="EUR" /> / <PrintPrice value={rec.allLimit} currency="EUR" />
                        <small className={b('count')}>({rec.allCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="Enabled"
                    align="right"
                    dataIndex="enBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={bal} currency="EUR" /> / <PrintPrice value={rec.enLimit} currency="EUR" />
                        <small className={b('count')}>({rec.enCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="Invalid"
                    align="right"
                    dataIndex="invBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={bal} currency="EUR" /> / <PrintPrice value={rec.invLimit} currency="EUR" />
                        <small className={b('count')}>({rec.invCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="Disabled"
                    align="right"
                    dataIndex="disBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={bal} currency="EUR" /> / <PrintPrice value={rec.disLimit} currency="EUR" />
                        <small className={b('count')}>({rec.disCnt})</small>
                      </div>
                    )}
      />
      <Table.Column title="With card"
                    align="right"
                    dataIndex="hasCardBal"
                    render={(bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={bal} currency="EUR" /> / <PrintPrice value={rec.hasCardLimit} currency="EUR" />
                        <small className={b('count')}>({rec.hasCardCnt})</small>
                      </div>
                    )}
      />
    <Table.Column title="Rate"
                  align="right"
                  dataIndex="codesRates"
                  render={value => <PrintPercent value={value} />}
    />
      <Table.Column title="Balance to ALL %"
                    align="right"
                    dataIndex="balToAll"
                    render={value => <PrintPercent value={value} />}
      />
      
    </Table>
  </div>);

}

export default AccountGroupBalance;
